.grw-slider{
    .rplg-slider-next, .rplg-slider-prev{

        width: rem(20 * 1.3);
        height: rem(11 * 1.3);
        background-image: url("/app/themes/rnrtires/assets/img/arrow.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-color: transparent;
        opacity: 0.8;

        &:hover, &:focus{
            opacity: 1;
        }

        span{
            display: none;
        }
    }

    .rplg-slider-next{
        right: -45px !important;
        transform: rotate(-90deg);
    }
    .rplg-slider-prev{
        left: -45px !important;
        transform: rotate(90deg);
    }
}
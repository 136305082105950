.module--services{

}

.service-block--grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(rem(170), 1fr));
    grid-gap: rem(10);
    justify-items: stretch;
    justify-content: center;

    @include for-specific-size(520){
        grid-template-columns: repeat(auto-fit, minmax(rem(190), 1fr));
    }

    @include for-size('tablet-portrait-up'){
        grid-template-columns: repeat(auto-fit, minmax(rem(280), 1fr));
        grid-gap: rem(20);
    }

    @include for-size('tablet-landscape-up'){
        grid-template-columns: repeat(auto-fit, minmax(rem(280), 0.33fr));
        grid-gap: rem(20);
    }

    @include for-size('desktop-up'){
        grid-template-columns: repeat(auto-fit, rem(380));
    }
}


.service-block{
    a{
        display: block;
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            object-fit: cover;
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
            transform: scale(1);
            transition: transform $animation-speed, filter $animation-speed;
        }

        &:hover, &:focus{
            img{
                filter: grayscale(0%);
                transform: scale(1.1);
            }
        }
    }
}

.title-tag{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    color: color(white);
    text-align: center;
     padding: 0 rem(20) rem(15) rem(20);
    min-height: rem(50);

    svg{
        fill: color(white);
        width: 100%;
        height: 100%;
    }

    .h-subheadline{
        line-height: 1;
    }

    @include for-size('to-tablet-portrait-only'){

        .h-subheadline{
            font-size: rem(16);
            line-height: 1.1;
        }
    }

    @include for-size('tablet-landscape-up'){
        .h-subheadline{
            font-size: rem(20);
        }
    }

    @include for-size('desktop-up'){
        .h-subheadline{
            font-size: rem(30);
        }
    }

    @include for-size('tablet-landscape-up'){
         padding: 0 rem(20) rem(30) rem(20);
    }
}

.service--icon{
    @include svg_wrapper_square(40);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.service--icon-icon-experience{
    @include svg_wrapper_square(32);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.service--icon-icon-team{
    @include svg_wrapper_square(32);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.service--icon-icon-trophy{
    @include svg_wrapper_square(28);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.service--icon-icon-investement{
    @include svg_wrapper_square(30);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.service--icon-icon-number-1{
    @include svg_wrapper_square(28);
    margin: 0 auto rem(5) auto;

    @include for-size('tablet-landscape-up'){
         @include svg_wrapper_square(65);
         margin: 0 auto rem(15) auto;
    }
}

.title-tag--bkgd{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: color(red--dark);
    mix-blend-mode: multiply;
    min-height: rem(50);

    &.title-tag--bkgd-w-icon{
        &:before{
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            width: rem(80);
            height: rem(80);
            background-color: color(red--dark);
            transform: translate(-50%, -50%);
            border-radius: 100%;
        }
    }

     @include for-size('tablet-landscape-up'){
        min-height: rem(70);
        padding: rem(20) rem(20) rem(20) rem(30);

        &.title-tag--bkgd-w-icon{
            &:before{
                width: rem(100);
                height: rem(100);
            }
        }
     }
}

@use "sass:math";

.module--rnr-difference{
    .layout--smallwidth{
        max-width: rem(math.div(768, 2));
    }

    @include for-size('tablet-landscape-up'){
        .grid{
            > div:first-child{
                padding: 0 0 rem(20) 0;
            }

            > div:last-child{
                padding: 0 0 0 rem(20);
            }
        }
    }
}
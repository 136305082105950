#footer{
    position: relative;
    padding-bottom: rem(81);
    
    @include for-size('phone-only') {
    .logo-social-container {       
                    flex-direction: column;
        }
    }

    .logo{
        svg{
            fill: color(white);
        }
    }

    a{
        text-decoration: none;
    }

    @include for-size('tablet-landscape-up'){
        padding-bottom: rem(40);
    }

    @include for-specific-size(1680){
        max-width: rem(1678);
        margin: 0 auto;
    }
}

.ftr--email-signup{
    position: relative;

    .img--bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    h3{
        letter-spacing: trackingToEm(60);
        font: {
            size: rem(30);
            weight: 600;
        };
    }

    > .d-f{
        position: relative;
        z-index:  2;
        flex-direction: column;
        align-items: center;
    }

    a{
        font-weight: bold;
    }

    @include for-size('tablet-landscape-up'){
        padding: {
            top: rem(5);
            bottom: rem(25);
        }

        h3{
            margin-bottom: 0;
            font-size: rem(30);
        }

        > .d-f{
            flex-direction: row;
            align-items: center;

            > .d-f{
                position: relative;

                p.privacy{
                    position: absolute;
                    right: rem(12);
                    bottom: rem(-25);
                    margin: 0;
                    padding: 0;
                }
             }
        }
    }
}

.nav--footer{
    display: none;

    @include for-size('tablet-landscape-up'){
        display: block;
        width: 80%;

        > ul{
            column-count: 3;
            column-gap: rem(40);
            column-fill: balance;

            li{
                padding: 0;
                a, span{
                    display: block;
                    line-height: 2.2;
                }

                span{
                    font-weight: 400;
                }
            }
        }
    }
}

.nav--footer-cols{

    @include for-size('tablet-landscape-up'){
        display: flex;
        justify-content: space-between;
        width: 80%;

        > ul{
            columns: initial;
            // width: calc(100% - 40px);
            padding: 0 rem(20);
        }
    }

    .gap{
        display: block;
        height: rem(36);
    }
}

.nav--social{
    text-align: center;

    nav{
        padding-top: rem(10);
        justify-content: center;

        a{
            transition: background-color $animation-speed;

            &:not(:last-child){
                margin-right: rem(20);
            }

            &:hover{
                background-color: color(gray--light-tint);
            }
        }
    }

    @include for-size('tablet-landscape-up'){
        width: auto;
        text-align: left;

        nav{
            justify-content: flex-start;

            a:not(:last-child){
                margin-right: rem(10);
            }
        }
    }
}

.nav--legal {
    @include for-size('to-tablet-portrait-only'){
        padding: {
            top: rem(10);
            left: 0;
            right: 0;
        };
        margin: {
            left: rem(-30);
            right: rem(-30);
        };

        a{
            display: inline-block;
            padding: 0 rem(10);
        }

        .d-f{
            flex-direction: column;

            div{
                padding: rem(10);
                text-align: center;

                &:first-child{
                    order: 2;
                }

                &:last-child{
                    order: 1;
                }
            }
        }
    }
}

.nav--sitemap{
    overflow: hidden;
    position: relative;
    margin: {
        top: rem(30);
        left: rem(-30);
        right: rem(-30);
    };

   button  {
        background-color: color(gray--medium);
        color: color(white);
        text-align: center;
        width: 100%;
        padding: rem(20);
        @extend .h-title--lg;

        &:after{
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: rem(15);
            border: {
                top: rem(10) color(white) solid;
                left: rem(10) transparent solid;
                right: rem(10) transparent solid;
                bottom: rem(1) transparent solid;
            };
            transition: transform $animation-speed;
            transform: rotate(0deg);
            transform-origin: center;
        }
    }

    &.active{
          button {
            &:after{
                transform: rotate(-180deg);
            }
        }
    }
}

.sitemap{
    position: relative;
    top: 0;
    padding: rem(20);

    ul{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(rem(147), 1fr));
        grid-gap: rem(15);
        justify-items: stretch;
        overflow: hidden;
        max-height: 0;
        transition: max-height 400ms;

        .active &{
            max-height: rem(550);
        }
    }
}

.popover {
    position: absolute;
    display: none;
    max-width: rem(340);
    background-color: color(white);
    border: rem(1) solid color(gray);
    border-radius: rem(20);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);

    @include for-size('tablet-portrait-up'){
        max-width: rem(768);
    }
}

.popover .popover-content {
    position: relative;
    padding: rem(20);
    font-size: rem(13);

    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: color(white);
        border-width: 10px;
        margin-left: -10px;
    }
    &:before {
        border-color: rgba(238, 238, 238, 0);
        border-top-color: color(gray);
        border-width: 11px;
        margin-left: -11px;
    }
}

.tip {
    cursor: pointer;
    transition: color $animation-speed;
}
.module--body-content {
    .d-f {
        @include for-size('tablet-landscape-up') {
            article {
                width: 55%;
            }

            aside {
                width: 45%;
            }
        }
    }
}

.without-hero {
    margin-top: 3.5rem;

    @media only screen and (max-width: 899px) {
        margin-top: 0;
    }
}

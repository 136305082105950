.layout--smallwidth{
	max-width: rem(768);
}

.layout--mediumwidth{
	max-width: rem(960);
}

.layout--largewidth{
	max-width: rem(1024);
}

.layout--extralargewidth{
	max-width: rem(1280);
}

// .layout--post-tips-guides {
//     max-width: rem(1370);
// }

.columns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.column{
    width: calc(100% - 20px);
    margin: 0 rem(10);

    @include for-size('tablet-portrait-up'){
        // width: calc(50% - 20px);
        width: auto;
        margin: rem(10);
    }

    @include for-size('tablet-landscape-up'){
        width: auto;
    }
}


.grid{
    @include for-size('tablet-landscape-up'){
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(rem(400), 1fr));
        grid-gap: rem(10);
    }
    justify-items: stretch;
}

.content-grid{
     @include for-size('tablet-landscape-up'){
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: max-content;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
     }
}
.location-bar {
  display: flex;
  padding: rem(5) rem(20) rem(10) rem(20);

  @include for-size("tablet-landscape-up") {
    display: none;
  }
}

.location-hero {
  // padding: rem(30) rem(20) rem(20) rem(20);

  @include for-size("to-tablet-portrait-only") {
    .btn {
      width: calc(100% - 40px);
      text-align: center;
    }

    form {
      .btn {
        width: 100%;
      }
    }
  }
}

.location-details {
  width: 100%;
}

.location-address,
.location-map {
  border-radius: rem(15);

  @include for-size("tablet-landscape-up") {
    border-radius: 0;
  }
}

@include for-size('phone-only') {
  .action-container {
    text-align: center;
  }
}

.location-address {
  background-color: color(white);
  padding: rem(20);
  width: calc(100% - 40px);
  margin: rem(30) auto;

  @include for-size("tablet-landscape-up") {
    width: calc(50% - 40px);
    margin: 0;
    border-top-left-radius: rem(15);
    border-bottom-left-radius: rem(15);
  }
}

.location-map {
  padding-top: 56.25%;
  margin-top: rem(20);
  width: 100%;

  @include for-size("tablet-landscape-up") {
    width: 50%;
    margin-top: 0;
    border-top-right-radius: rem(15);
    border-bottom-right-radius: rem(15);
    padding-top: 0;
  }
}

.location-hours {
  background-color: color(white);
  padding: rem(20);
  margin-top: rem(20);
  border-radius: rem(15);

  table {
    width: 100%;

    tr {
      td {
        @extend %font-myriad-pro-semibbold;

        @include for-size("to-tablet-portrait-only") {
          font-size: rem(20);
        }
      }
    }
  }

  @include for-size("tablet-landscape-up") {
    min-width: calc(300px - 40px);
    margin-top: 0;
  }
}

.microsite-header {
  border: none;

  .microsite-quick-links {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .microsite-nav--mobile {
    ul {
      display: flex;
      align-items: center;
      padding-top: 1rem;

      li {
        a {
          font-weight: bold;
          margin-bottom: 0.5rem;

          &:hover {
            color: #981b1e;
          }
        }
      }
    }
  }

  .location-microsite--nav {
    &.mobile {
      padding: 0;

      button {
        &.navbar-toggler {
          .navbar-toggler-icon {
            width: 1em;
            height: 1em;
          }
        }
      }
    }
  }
}

.main-nav {
  .bg-white--light {
    box-shadow: 0px 12px 24px -13px rgba(0, 0, 0, 0.5);

    &.location-microsite--nav {
      ul {
        padding: 0.8rem 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;

        li {
          a {
            padding: 0;
            color: #981b1e;

            &:hover {
              color: darken(#981b1e, 10%);
            }

            &:after {
              background-color: #981b1e;
            }

            &.active {
              &:after {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
  }
}

.location-microsite,
.microsite-page,
.location-microsite--blog,
.franchisee-single {
  @media (min-width: 900px) {
    .p-t-40--landscape {
      padding-top: 2.5rem;
    }
  }

  .location-microsite--hero,
  .microsite-page--hero {
    position: relative;

    .hero-image--desktop {
      padding-top: 1rem;
      max-height: 542px;
    }

    .hero-image--desktop,
    .hero-image--mobile {
      width: 100%;
    }

    .hero-content--container {
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10%;

        &.with-overlay {
          background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
        }

        &.module--text_pos_right {
          justify-content: end;

          &.with-overlay {
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
          }

          &>* {
            text-align: right;
          }
        }

        .hero-content {
          width: 100%;

          h1 {
            font-weight: 700;
          }

          &.hero-content--light {
            color: #981b1e;
          }

          &.hero-content--dark {
            color: #981b1e;

            @include media-breakpoint-up(lg) {
              color: white;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 0;
          left: 0;

          .hero-content {
            width: 35%;
          }
        }
      }
    }
  }
}

.microsite-page {
  @media (min-width: 900px) {
    .p-t-40--landscape {
      padding-top: 2.5rem;
    }
  }
}

.location-microsite--blog {
  .blog--entries {
    .blog-posts {
      .post {
        &.no-image {
          a {
            .post--excerpt {
              height: 100%;
            }

            &:hover {
              .post--excerpt {
                h2 {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.content-container--no-hero {
  padding-top: 3.5rem;
}

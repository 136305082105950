.page-template-page-branding {
    .header-navs {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .nav-link {
        background-color: #ededed;
        color: black;
        border-radius: 0%;
        width: 225px;
        height: 40px;
        text-align: center;
    }

    .nav-link.active {
        background-color: #981b1e;
        color: white;
        display: block;
        text-align: center;
        padding: 7px 10px;
        position: relative;
    }

    .nav-link.active::after {
        content: '';
        position: relative;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom-color: white;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }

    .font-header {
        @extend %font-myriad-pro-semibbold;
        padding-top: 40px;
    }

    .font-list {
        font-size: 14px;

        span {
            font-size: 12px;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: 400;
            color: #444444;
        }
    }

    .arial-reg {
        font-family: Arial, sans-serif;
    }

    .arial-itl {
        font-family: Arial, sans-serif;
        font-style: italic;
    }

    .arial-bld {
        font-family: Arial, sans-serif;
        font-weight: bold;
    }

    .arial-bld-itl {
        font-family: Arial, sans-serif;
        font-style: italic;
        font-weight: bold;
    }

    .arial-blk {
        font-family: Arial, sans-serif;
        font-weight: 700;
        color: black;
    }

    .myriad-pro-reg {
        @extend %font-myriad-pro;
    }

    .myriad-pro-itl {
        @extend %font-myriad-pro;
        font-style: italic;
    }

    .myriad-pro-semi-bld {
        @extend %font-myriad-pro-semibbold;
    }

    .myriad-pro-bld {
        @extend %font-myriad-pro-bold;
    }

    .myriad-pro-bld-itl {
        @extend %font-myriad-pro-bold;
        font-style: italic;
    }

    .myriad-pro-blk {
        @extend %font-myriad-pro;
        font-style: normal;
        font-weight: 700;
        color: black;
    }

    .logo-nav-lnk {
        background-color: #C7C7C7;
    }

    .logos-section {
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        @include for-size('phone-only') {
            display: flex;
            flex-direction: column;
        }
    }

    .logo-container {
        height: 150px;
        width: 150px;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logos-imgs-1 {
        max-width: 100%;
        max-height: 100%;
    }

    .logo-btn-wrapper {
        padding-left: 10px;
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

    .download-btn {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 40px;
        width: fit-content;
        font-size: 12px;
    }

    .cl-blk-btwn {
        margin-left: 10px;
        text-align: center;
    }

    .color-container {
        display: flex;
        justify-content: center;
    }

    .color-block {
        margin-top: 40px;
        height: 150px;
        width: 200px;
    }


    .color-subtitle {
        margin-top: -20px;
        font-size: 12px;
        text-align: center;
    }
}
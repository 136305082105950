.module--hero-image{
    // @include maskSquare();

    .image-panel--content{
        order: 2;
    }

    .image-panel--photo{
        order: 1;
        img{
            object-fit: cover;
        }
    }

     @include for-size('tablet-landscape-up'){
        .image-panel--content{
            order: 1;
        }

        .image-panel--photo{
            order: 2;
        }

        &.module--text_pos_right{
            .d-f{
                justify-content: flex-end;
            }
        }
     }
}

.module--monotone_effect{
    @include for-size('tablet-landscape-up'){
        position: relative;
        overflow: hidden;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: transparentize(color(red--dark), 0);
            mix-blend-mode: multiply;
            width: 70vw;
            height: 100%;
            clip-path: polygon(15% 8.2%, 100% 8.2%, 100% calc(100% - 3px), 0% calc(100% - 3px));
            transform: translateX(100%);
            transition: transform $animation-speed * 1.5 ease-in, width $animation-speed * 1.5 ease-in;

        }

        .image-panel--photo{
            transition: filter $animation-speed * 1.5;
            filter: grayscale(100%);
        }


        &:hover{
            .image-panel--photo{
                filter: grayscale(0%);
            }
            .image-panel--content, .c-red--dark{
                color: color(white);
            }
            &:after{
                 transform: translateX(0%);
            }
        }
    }

    @include for-specific-size(950){
        &:after{
             width: 60vw;
        }
    }

    @include for-size('desktop-up'){
        &:after{
             width: 55vw;
        }
    }

    // @include for-specific-size(1500){
    //     &:after{
    //          width: 50vw;
    //     }
    // }

    // @include for-specific-size(1600){
    //     &:after{
    //          width: 55vw;
    //     }
    // }

    @include for-size('big-desktop-up'){
        &:after{
             width: 40vw;
        }
     }
}
.accordion{
    transition: height $animation-speed * 2;
    overflow: hidden;
    will-change: height;

    &.closed{
        height: 0 !important;
    }

    @include for-size('tablet-portrait-up'){
        display: block;
        height: auto !important;

         &.closed{
            height: auto !important;
         }
    }
    a{  }
}

.states{
    a, span{
        display: block;
        padding-bottom: rem(4);
    }
}

@media (max-width: 599px ) {
    [data-module="accordion"]{
        .column{
            h2:first-child{
                margin-top: 0;
            }
        }
    }
}
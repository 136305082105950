.news-posts{

    .form-search{
        display: flex;
        justify-content: center;
        padding-left: rem(40);
        padding-right: rem(40);
        flex-direction: column-reverse;
        align-items: center;

        > nav{
            margin-top: rem(30);

            a{
                @extend .h-title;
                text-decoration: none;
                color: color(gray--dark);
                outline: none;
                padding: 0;
                @include navigation_hover_effect(red--dark, -5);
            }

            li{
                &:not(:last-child){
                    position: relative;

                    &:after{
                        position: absolute;
                        right: 0;
                        top: 20%;
                        bottom: 20%;
                        content: "";
                        border-right: 2px color(gray--dark) solid;
                    }
                }

            }
        }

        form{
            font-size: rem(14);
            white-space: nowrap;
        }

        input[type="search"]{
            margin-right: rem(10);
            min-width: rem(200);
        }

        @include for-size('tablet-portrait-up'){
            flex-direction: row;
            justify-content: space-between;

            > nav{
                padding-bottom: rem(7);
            }
        }
    }

    .column{
        @include for-size('tablet-landscape-up'){
            width: calc(50% - 20px);
            margin: rem(10);
        }
    }

    .pagination{
        a, span{
            position: relative;
            display: inline-block;
            padding: 0 rem(5);
        }

        a{
            font-weight: 700;
            // color: color(gray--medium);
            text-decoration: none;
        }

        .dots{
            color: color(gray--medium);
            padding: 0;
        }

        .current{
            font-weight: 700;
            color: color(white);

            &:after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: rem(25);
                height: rem(25);
                background-color: color(red--dark);
                border-radius: 50%;
                z-index: -1;
            }
        }
    }
}
.icons--round{
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(23);
    height: rem(23);
    background-color: color(white);
    padding: rem(5);
    border-radius: 100%;

    svg{
        fill: color(gray--dark);
        width: 80%;
        height: 80%;
    }
}

.icon--tirerepair{
    height: 36%;
    max-width: initial !important;
    width: auto !important;
}
.v-enter{
    opacity: 0;
}

.v-enter-to{
    opacity: 1;
}

.v-enter-active{
    transition: opacity $animation-speed * 2 ease;
}


.v-leave{
    opacity: 1;
}

.v-leave-to{
     opacity: 0;
}

.v-leave-active{
    transition: opacity $animation-speed * 2 ease;
}
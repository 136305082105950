.photo-gallery{
    width: calc(100vw - 130px);
    max-width: rem(1024);
    margin: 0 auto rem(40) auto;
    padding: 0;

    button.lightbox{
        padding: 0;
        box-shadow: inset 0 0 0 0 color(red--dark);
        transition: box-shadow $animation-speed;
        overflow: hidden;

        img{
            display: block;
            z-index: -1;
            position: relative;
            transform: scale(1);
            transition: transform $animation-speed;
        }

        &:hover, &:focus{
            box-shadow: inset 0 0 0 rem(7) color(red--dark);

            img{
                transition: transform 10s;
                transform: scale(1.2);
            }
        }
    }

    .flickity-slider{
        > div{
            width: calc(100% - 20px);

            @include for-size('tablet-portrait-up'){
                width: calc(50% - 20px);
            }

            @include for-size('tablet-landscape-up'){
                width: calc(25% - 20px);
            }
        }
    }

    .flickity-prev-next-button{
        &.previous{
            left: 0;
            transform: translate(calc(-100% - 20px), -50%);
        }

        &.next{
            right: 0;
            transform: translate(calc(100% + 20px), -50%);
        }
    }
}

.lightbox-close{
    position: absolute;
    right: rem(20);
    top: rem(20);
    background-color: transparentize(color(gray--dark), 0.2);
    color: color(white);
    border-radius: rem(5);
    font-size: rem(20);
    line-height: 1;
    padding: rem(1) rem(8) rem(4) rem(8);
    transition: background-color $animation-speed;

    &:hover, &:focus{
        background-color: transparentize(color(gray--dark), 0);
    }
}

.lightbox-modal{
    background-color: color(white);
    padding: rem(15);
    border-radius: rem(12);

    figcaption{
        padding-top: rem(10);
    }
}
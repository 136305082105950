.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: transparentize(color(dark), 0.5);

    &[aria-hidden="true"] {
        opacity: 0;
        display: none;
    }
}

.modal-content-wrapper {
    position: fixed;
    top: rem(60);
    left: 50%;
    height: calc(100vh - 80px);
    max-height: 70vh;
    transform: translateX(-50%);
    overflow: auto;

    @include for-size('tablet-portrait-up') {
        top: rem(176);
        // left: 50%;
        height: auto;
        max-height: 70vh;
        // transform: translate(-50%, -50%);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.modal-content {
    padding: rem(30);
}

.modal-content--acf {
    height: 100%;

    >p:first-of-type {
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
}

.modal-close {
    position: absolute;
    top: rem(18);
    right: rem(18);
    padding: 0;
    margin: 0;
    width: rem(15);
    height: rem(15);

    svg {
        width: 100%;
        stroke: color(red--dark);
    }
}

.modal--form {
    .modal-content-wrapper {
        background-color: color(gray--light);
        width: calc(100vw - 120px);
        border-radius: rem(7.5);
    }
}

#modal-signup {
    $padding: 40%;

    .modal-content-wrapper {
        img {
            display: none;
        }
    }

    .modal-content-wrapper {
        width: 90vw;
        height: auto;
        max-width: rem(768);
        background-color: color(red--dark);
        color: color(white);
        border-radius: rem(32);
    }

    .modal-content {
        padding: rem(20);
        text-align: center;

        .h-title {
            margin-bottom: 0;
        }

        .flx-col {
            align-items: center;
        }
    }

    .modal-close {
        top: rem(12);
        right: rem(20);

        svg {
            stroke: color(white);
        }
    }


    .email-sign-up-form {
        margin: rem(20) 0 0 0;
    }

    @include for-size('tablet-portrait-up') {
        .modal-content-wrapper {
            img {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                width: $padding;
                height: auto;
            }
        }

        .modal-content {
            padding: {
                top: rem(60);
                bottom: rem(60);
                left: $padding + 2%;
            }

            ;
            text-align: left;

            .h-title {
                margin-bottom: rem(16);
            }

            .flx-col {
                align-items: normal;
            }
        }

        .newsletter-email-field {
            grid-column: span 8 / span 8;
        }

        .newsletter-zip-field {
            grid-column: span 4 / span 4;
        }

        .newsletter-submit {
            margin-top: rem(10);
        }
    }

}

.form-modal {
    position: fixed;
    top: rem(116);
    left: 50%;
    z-index: 500;
    transform: translateX(-50%);
    width: calc(100vw - 120px);
    background-color: color(gray--light);
    padding: rem(30);
    border-radius: rem(20);
    box-shadow: 0 0 rem(10) black;

    &:before {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background-color: transparentize(color(dark), 0.5);
        content: "";

        &[aria-hidden="true"] {
            opacity: 0;
            display: none;
        }
    }
}

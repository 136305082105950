/*
 * Lists
 ========================================================================== */
.list--clean{
    margin: 0;
    list-style-type: none;
}


.list--checkmark, .list--checkmark, .list--checkmark{

}

.contact-info-list{
    padding: 0;
    list-style-type: none;

    li{
        text-align: left;
        padding: rem(4) 0 rem(4) rem(30);

        background:{
            repeat: no-repeat;
            position: left center;
            size: rem(19);
        }
    }
}

.list--checkmark{
    padding: 0;
    list-style-type: none;

    li{
        text-align: left;
        padding: rem(4) 0 rem(4) rem(30);
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuNTQ1IDBsLTcuODMgMTAuMDc1TDMuMDU0IDYuNDggMCAxMC40MzlsOC42MDYgNi42MzhMMTkuNDkyIDMuMDY5eiIgZmlsbD0iIzk4MWIxZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
        background-repeat: no-repeat;
        background-position: left rem(11);
    }
}

.list--phone{
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS4yIDIxLjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIxLjIgMjEuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5ODFCMUU7fQo8L3N0eWxlPgo8ZyBpZD0icGhvbmVfaWNvbiI+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTUsMjEuMkMxMi41LDIxLDcuOCwxOC41LDUuMywxNkMyLjcsMTMuNCwwLjIsOC43LDAsNi4zbDAsMGwwLDBjMC0xLjksMC43LTMuNywyLTVMMi4yLDEKCQljMC45LTAuNiwxLjktMC45LDIuOS0wLjlsMC41LDBsMC4zLDAuNGMxLjMsMS41LDIuMywzLjIsMy4xLDVsMC4xLDAuMmwwLDAuMmMwLDAuNy0wLjYsMS4yLTEuOSwyLjRDNyw4LjQsNi45LDguNSw2LjcsOC43CgkJYzAuMiwwLjYsMSwyLjIsMi4zLDMuNWMxLjMsMS4zLDIuOCwyLjEsMy41LDIuM2MwLjEtMC4xLDAuMy0wLjMsMC40LTAuNWMxLjItMS4zLDEuNy0xLjgsMi40LTEuOWwwLjIsMGwwLjIsMC4xCgkJYzEuOCwwLjgsMy41LDEuOCw1LDMuMWwwLjQsMC4zbDAsMC41YzAsMS0wLjMsMi0wLjksMi45bC0wLjIsMC4yQzE4LjcsMjAuNCwxNi45LDIxLjIsMTUsMjEuMnogTTIsNi4yYzAuMSwxLjgsMi4zLDYsNC42LDguNAoJCWMyLjQsMi4zLDYuNyw0LjYsOC40LDQuN2MxLjMsMCwyLjUtMC41LDMuNS0xLjRjMC4yLTAuNCwwLjQtMC45LDAuNS0xLjNjLTEuMS0wLjktMi4zLTEuNi0zLjYtMi4yYy0wLjMsMC4zLTAuOCwwLjgtMS4xLDEuMQoJCWMtMC41LDAuNS0wLjcsMC44LTAuOSwwLjljLTEuNCwxLTQuOC0xLjctNS44LTIuN2MtMS4xLTEuMS0zLjctNC41LTIuNy01LjhjMC4yLTAuMiwwLjQtMC41LDAuOS0wLjlDNi4xLDYuNSw2LjYsNiw2LjksNS43CgkJQzYuNCw0LjQsNS42LDMuMiw0LjcsMi4xQzQuMywyLjIsMy44LDIuNCwzLjQsMi42QzIuNSwzLjYsMiw0LjgsMiw2LjJ6Ii8+CjwvZz4KPC9zdmc+Cg==');


}

.list--email{
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMCAxNS4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMCAxNS4yOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6Izk4MUIxRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNy4zLDBIMi43QzEuMywwLDAuMSwxLjEsMC4xLDIuNkwwLDMuOGwwLjEsMGwwLDB2OC43YzAsMS41LDEuMiwyLjcsMi43LDIuN2gxNC42YzEuNSwwLDIuNy0xLjIsMi43LTIuN1YzLjkKCXYwbDAsMFYyLjd2MEMyMCwxLjIsMTguOCwwLDE3LjMsMHogTTE3LDIuMWwtNyw0TDMsMi4xTDIuOSwyaDE0LjNMMTcsMi4xeiBNMTgsMTIuNmMwLDAuNC0wLjMsMC43LTAuNywwLjdIMi43CgljLTAuNCwwLTAuNy0wLjMtMC43LTAuN1YzLjhsNy43LDQuNGMwLDAsMCwwLDAuMSwwbDAsMGMwLDAsMC4xLDAsMC4xLDBjMCwwLDAsMCwwLDBsMCwwYzAsMCwwLDAsMCwwYzAsMCwwLDAsMCwwYzAsMCwwLjEsMCwwLjEsMAoJYzAsMCwwLDAsMCwwTDE4LDMuOVYxMi42eiIvPgo8L3N2Zz4K');
    word-break: break-word;
}

// SETTINGS
// Override default Hamburgers settings
// ==================================================

// $hamburger-padding-x           : 15px;
// $hamburger-padding-y           : 15px;
$hamburger-layer-width         : 33px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 7px;
$hamburger-layer-color         : #414141;
$hamburger-layer-border-radius : 7px;
// $hamburger-hover-opacity       : 0.7;
// $hamburger-active-layer-color  : $hamburger-layer-color;w
// $hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.

// $hamburger-hover-use-filter   : false;
// $hamburger-hover-filter       : opacity(50%);
// $hamburger-active-hover-filter: $hamburger-hover-filter;

$hamburger-types: (spin);

// Load in Hamburgers directly from NPM
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss"
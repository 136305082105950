table.table--data{
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 auto;
    width: calc(100% - 60px);

    th{
        background-color: color(gray--light);
        color: color(gray--dark);
        padding: 0 rem(12) rem(15) rem(12);
        text-align: left;
        font-size: rem(14);
        vertical-align: bottom;
    }

    td{
        padding: rem(10) rem(13);
        font-size: rem(14);
    }

    tr:nth-child(even){
        td{
            background-color: color(white);
        }
    }

    tr:nth-child(odd){
        td{
            background-color: lighten(color(gray--light), 3%);
        }
    }
}
.module--video{
    padding: rem(40) 0;
}

.module--marketing_video{
    .d-f{
        flex-wrap: wrap;

        .marketing_content, .video-player{
            flex-basis: 100%;
        }

        .marketing_content{
            order: 1;
        }

        .video-player{
            order: 2;
        }

        @include for-size('tablet-landscape-up'){
            .marketing_content, .video-player{
                 flex-basis: calc(50% - 20px);
            }

            .marketing_content{
                order: 2;
            }

            .video-player{
                order: 1;
            }
        }
    }
}
.btn{
    display: inline-block;
	background-color: color(gray--dark);
	color: color(white);
    outline: 0;
    border: 0;
    padding: rem(11.5) rem(27.6);
    border-radius: rem(50);
    font:{
        size: rem(18);
    };
    @extend %font-myriad-pro-bold;
    text:{
        decoration: none;
        transform: uppercase;
    }
    letter-spacing: trackingToEm(100);
    transition: background-color $animation-speed, border-color $animation-speed, color $animation-speed;

    &:hover, &:focus, &:active{
        color: color(white);
        background-color: color(gray--medium);

    }

    @include for-size('tablet-landscape-up'){
        padding: rem(10) rem(24);
        font:{
            size: rem(16);
        };
    }
}

.btn--skinny{
    @extend .btn;
    border: 0;
    padding: rem(8) rem(30);
}

.btn--white{
    @extend .btn;
    background-color: color(white);
    color: color(red--dark);
    border: rem(2) color(white) solid;

    &:hover, &:focus, &:active{
        color: color(white);
        border-color: color(white);
    }
}

.btn--red{
    @extend .btn;
    background-color: color(red--dark);
    color: color(white);
    border: rem(2) color(red--dark) solid;

    &:hover, &:focus, &:active{
        color: color(white);
        background-color: color(red);
        border-color: color(red);
    }
}

.btn--share, .btn--clipboard{
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40);
    height: rem(40);
    background-color: color(gray--dark);
    border-radius: 50%;
    transition: background-color $animation-speed;
    padding: rem(6);

    svg{
        width: 80%;
        fill: color(white);
    }

    &:hover, &:focus{
        background-color: color(red--dark);
    }
}

@keyframes pop {
  0% {
    transform: translateY(0%) scale(0);
    opacity: 0;
  }

  20%, 80% {
    opacity: 1;
    transform: translateY(-100%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateY(0%) scale(0);
  }
}

.btn--clipboard{
    position: relative;

    .copied{
        display: block;
        position: absolute;
        top: rem(-5);
        background-color: color(dark);
        color: color(white);
        padding: rem(4);
        border-radius: rem(5);
        box-shadow: rem(2) rem(2) rem(4) transparentize(color(dark), 0.65);
        pointer-events: none;
        opacity: 0;

        &.animate{
            animation-duration: 2s;
            animation-name: pop;
        }
    }
}

.tooltip-btn{
    font-size: rem(16);
    vertical-align: top;

    &:focus{
        & + .tooltip{
            display: block;
            position: absolute;
            top: rem(20);
            right: 0;
            z-index: 50;
        }
    }
}

.btn-floats{
    text-align: center;
    display: inline-block;

    a{
        width: auto;
    }
}

.floating_button{
    position: fixed;
    bottom: rem(0);
    left: 0;
    right: 0;
    opacity: 1;
    pointer-events: all;
    z-index: 50;
    transform: translate3d(0, 0, 0);
    transition: transform $animation-speed * 2 cubic-bezier(0.52, 0.16, 0.24, 1);
    background-color: transparent;
    padding: rem(20);
    text-align: center;

    @include for-size('tablet-landscape-up'){
        background-color: color(red--dark);
    }

   .btn{
        text-align: center;
        background-color: color(red--dark);
        color: color(white);
        box-shadow: color(dark) 0 0 rem(15) rem(-8);

        @include for-size('tablet-landscape-up'){
            background-color: color(white);
            color: color(red--dark);
            box-shadow: none;
        }
   }

    &.hidden{
        display: block !important;
        pointer-events: none;
        transform: translate3d(0, 100%, 0);
    }
}
.module--services-content.is-photo{
    @include for-size('tablet-landscape-up'){
        &.module--text_pos_right{
            .d-f {
                justify-content: flex-end;

                .image-panel--photo{
                    position: absolute;
                    left: 0;
                    height: 100%;

                    img{
                        height: 100%;
                        object-fit: cover;
                        object-position: top left;
                    }
                }

                 .image-panel--content{
                    position: static;
                    top: initial;
                    float: left;
                    transform: none;
                    background-image: linear-gradient(90deg, rgba(255,255,255, 0), color(white) 62.125%);
                 }
            }
        }

        &.module--text_pos_left{
            .d-f {
                .image-panel--photo{
                    position: absolute;
                    right: 0;
                    height: 100%;

                    img{
                        height: 100%;
                        object-fit: cover;
                        object-position: top right;
                    }
                }

                 .image-panel--content{
                    position: static;
                    top: initial;
                    width: 60%;
                    max-width: rem(950);
                    padding-right: rem(260);
                    transform: none;
                    background-image: linear-gradient(90deg, color(white) 62.125%, rgba(255,255,255, 0));
                 }
            }
        }
    }

    &.bg-gray--light{
        .image-panel--content{
            background-image: none;
        }
    }
}

.module--services-content.is-video{
    @include for-size('tablet-landscape-up'){
        .d-f{
            // min-height: rem(550);

            .image-panel--video{
                padding-top: 0;
            }

            .image-panel--content{
                position: static;
                transform: none;
                right: 0;
                padding-top: 0;
            }
        }
    }
}

.module--our-programs{

}

.program-block--grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(rem(300), 1fr));
    grid-gap: rem(20);
    justify-items: stretch;
}


.program-block{
    a{
        display: block;
        position: relative;
        padding-bottom: 150%;
        overflow: hidden;

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: transform $animation-speed;
        }

        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }

    .title-tag--bkgd{
        &:before{
            display: none;
        }
    }
}
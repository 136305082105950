.page-payment-options{
    .module--text{
        article{
            padding-bottom: rem(10);
        }
    }
    .module--need-want{
        margin-bottom: rem(85 * 2);
    }

    .module--video{
        background-color: color(gray--light);
        padding-bottom: 0;

        @include for-size('tablet-landscape-up'){
            background-color: color(white);
        }
    }
}
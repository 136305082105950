/*
 * Colors
 ========================================================================== */
$colors: (
	white: #fff,
	dark: #262626,
    gray--dark: #414141,
    gray--medium: #707070,
    gray--light: #ededed,
    gray--light-tint: #D5D5D5,
    gray: #acacac,
    gray--extra-light: #f7f7f7,
    red--dark: #981b1e,
    red: #e7131d,
    yellow: #e4ae27,
    yellow--light: #ffe9af,
);

@each $color-name, $color-hex in $colors {
    // Text Colors
	.c-#{"" + $color-name} { color: $color-hex; }

    // Fill Colors
    .f-#{"" + $color-name} { fill: $color-hex; }

    // Stoke Colors
    .stroke-#{"" + $color-name} { stroke: $color-hex; }

    // Link (child) Colors
    .lnk-c-#{"" + $color-name} { a, button{ color: $color-hex; } }

	// Background Colors
    .bg-#{"" + $color-name} { background-color: $color-hex; }
}

/*
 * Gradients
 ========================================================================== */
$gradients: (
    gray: linear-gradient(0.42turn, #ededed 60%, #acacac 100%),
    yellow: linear-gradient(0.42turn, #ffe9af 60%, #e4ae27 100%)
);

@each $gradient-name, $gradient-values in $gradients {
    .gr-#{"" + $gradient-name} {
        background: color(white) $gradient-values;
    }
}
.module--hero-carousel{
    position: relative;
    overflow: hidden;

    &:focus, *:focus{
        outline: 0;
    }

    .flickity-slider{
        width: 100vw;
    }

    .flickity-page-dots, .flickity-prev-next-button{
        position: absolute;
    }

    .flickity-page-dots{
        display: flex;
        left: 50%;
        top: 50%;
        bottom: initial;
        width: auto;
        transform: translate(-50%, -100%);
        @extend %flickity-page-dots;

        @include for-size('tablet-landscape-up'){
            transform: translate(0%, 0%);
            left: 0;
        }
    }

    .flickity-button{
        display: none;
        @extend %flickity-prev-next-buttons;

         @include for-size('tablet-landscape-up'){
            display: block;
         }
    }

    .flickity-prev-next-button{
        position: absolute;
        @include centerY();
        padding: 0;

        &.previous{
            left: rem(20);
        }

        &.next{
            right: rem(20);
        }
    }

}

.hero-carousel---slide{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: rem(1680);

     @include for-size('tablet-landscape-up'){
        display: inline;
     }
}

.hero-carousel---image{
    order: 1;

    img{

    }
}

.hero-carousel---content{
    order: 2;
    padding: rem(40) rem(20) rem(20) rem(20);
    text-align: center;

    .h-headline{
        max-width: rem(480);
        margin: 0 auto;
    }

    @include for-size('to-tablet-portrait-only'){
        .enable-quick-quote  &{
            p{
                margin: 0;
            }

            .btn{
                display: none;
            }
        }
    }

    @include for-size('tablet-landscape-up'){
        position: absolute;
        // top: initial;
        // bottom: 30%;
        top: 50%;
        bottom: initial;
        left: rem(70);
        // @include centerY();
        padding: rem(50) rem(60) rem(50) rem(20);
        width: 40%;
        text-align: left;
        transform: translateY(-50%);

        .h-headline{
            max-width: rem(480);
            margin: 0;
        }
    }

    @include for-size('desktop-up'){
         // bottom: 30%;
    }
}

.content-container {
  // TODO: Add more robust responsive container
  padding: 2rem 10%;

  &.dark {
    color: white;

    .layout {
      button {
        &.slick-arrow {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .featured_staff {
      .featured-staff--container {
        article {
          background: white;
        }
      }
    }

    .content-slider {
      .content-slide {
        background: white;
        border-radius: 1rem;
        color: black;
      }
    }

    .flex-content--offers {
      color: white;

      h2 {
        color: white;
      }
    }


    .accordion {
      --bs-accordion-active-color: #981b1e;
      --bs-accordion-btn-focus-border-color: #981b1e;
      --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(152, 27, 30, 0.25);
      --bs-accordion-active-bg: #ffffff;
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23981b1e'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'%2F%3E%3C%2Fsvg%3E");
    }
  }

  // TODO: Fiv hover bug on vertical tabs
  &.light {
    .layout {

      .d-flex {
        display: flex;
        ;

        .flex-column {
          flex-direction: column;
        }
      }

      &.tab_block {
        .nav-tabs {
          li {
            .nav-link {
              color: #981b1e;
              -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
              transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
            }

            .active {
              &.nav-link {
                color: #ffffff;
                background: #981b1e;
              }
            }
          }
        }

        .tab-content {
          padding: 1rem 0;
          flex: 1;
        }

        .vertical {
          .nav-tabs {
            border: none;

            li {
              button {
                border-radius: 0;
                border: none;

                &:hover {
                  border: none;
                }
              }
            }

            :first-child {
              button {
                border-radius: var(--bs-nav-tabs-border-radius) 0 0 0;
              }
            }

            :last-child {
              button {
                border-radius: 0 0 0 var(--bs-nav-tabs-border-radius);
              }
            }
          }

          .tab-content {
            padding: 0 1rem;
          }
        }
      }
    }

    .featured-blocks {
      article {
        .block {
          color: white;
          background: #981b1e;
        }
      }
    }
  }

  &.layout {}

  // Layout Styles
  .multiple-columns {
    .half-width {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.sub-layout_parent {
        &>.layout {
          width: 75%;

          @media only screen and (max-width: 576px) {
            width: 100%;
          }
        }
      }
    }
  }

  .featured_image {
    .featured-image--container {
      padding: 1rem 0;

      @media only screen and (min-width: 900px) {
        padding: 0;
      }

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  .featured_staff {
    .featured-staff--container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-row-gap: .5rem;
      color: #414141;

      @media only screen and (min-width: 576px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      article {
        padding: 1rem;
        border-radius: 1rem;
        background: #ededed;
        margin: 0 .5rem;
        display: flex;
        flex-direction: column;

        img {
          margin: 0 auto;
          border-radius: 100%;
        }

        .button-group {
          margin-top: 1rem;
        }
      }
    }
  }

  .accordion {
    --bs-accordion-active-color: #981b1e;
    --bs-accordion-btn-focus-border-color: #981b1e;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(152, 27, 30, 0.25);
    --bs-accordion-active-bg: #ffffff;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23981b1e'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'%2F%3E%3C%2Fsvg%3E");
  }

  .single_image_and_copy {
    .row {
      .image {
        .overlay-container {
          position: relative;

          img {
            width: 100%;
            border-radius: 1rem;
          }

          .overlay {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.75));
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  .alert {
    &.dark {
      color: white;

      button {
        filter: invert(1);
      }
    }
  }
}

.slick-slider {
  button {
    &.slick-arrow {
      position: absolute;
      top: calc(50% - 15px);

      &.prev-button {
        left: -3rem;
      }

      &.next-button {
        right: -3rem;
      }
    }
  }
}

.microsite-page--hero {
  button {
    &.slick-arrow {
      &.prev-button {
        left: 1rem;
        z-index: 99;
        fill: white;
      }

      &.next-button {
        right: 1rem;
        z-index: 99;
        fill: white;
      }
    }
  }

  .microsite-hero--slide {
    max-height: 100%;
    position: relative;
  }
}

.content-slider {
  .content-slide {
    padding: 1rem;
    margin: 0 1rem;

    .img {
      img {
        border-radius: 1rem;
        width: 100%;
      }
    }
  }
}

.google-map-block {
  .map-container {
    .acf-map {
      border-radius: 1rem;
    }
  }
}

.featured-blocks {
  article {
    margin-bottom: 1rem;

    .block {
      box-shadow: 1px 4px 3px -1px rgba(0, 0, 0, 0.54);
      border-radius: 1rem;
      background: white;
      color: #414141;
      height: 100%;

      img {
        border-radius: 1rem 1rem 0 0;
        width: 100%;
      }

      .block-content {
        padding: 1rem;
      }
    }
  }
}

.flexible-content--article {
  margin: 0 .5rem !important;
}

.flexible-content--articles {
  &>* {
    text-align: center;
  }
}

.flexible-content--article {
  .blog-posts {
    .post {
      margin: 0 .5rem;
    }
  }
}

.module--the_tour{
    > nav{
        display: flex;
        justify-content: center;
        align-items: center;

        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            font-size: rem(18);
            position: relative;

            &:after, &:before{
                content: "";
                width: rem(7);
                height: rem(7);
                transition: border-color $animation-speed;
            }
        }

        .prev{
            &:before{
                margin-right: rem(20);
                margin-top: rem(1);
                border-left: rem(2) color(gray--medium) solid;
                border-bottom: rem(2) color(gray--medium) solid;
                transform: rotate(45deg);
            }

            &:hover{
                &:before{
                    border-color: color(red);
                }
            }
        }

        .next{
            &:after{
                margin-left: rem(20);
                margin-top: rem(1);
                border-left: rem(2) color(gray--medium) solid;
                border-bottom: rem(2) color(gray--medium) solid;
                transform: rotate(-135deg);
            }

            &:hover{
                &:after{
                    border-color: color(red);
                }
            }
        }
    }

    .service-block--grid{
        display: flex;
        justify-content: center;

        .service-block{
            width: 100%;
            max-width: rem(414);
        }
    }
}
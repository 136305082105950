@keyframes slidedown {
    from{
        transform: translateY(-100%);
    }
    to{
        transform: translateY(0%);
    }
}

@keyframes slideup {
    from{
        transform: translateY(0%);
    }
    to{
        transform: translateY(-100%);
    }
}

.hdr_bar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    background-color: color(white);

    .hdr_border &{
        @include for-size('tablet-landscape-up'){
            border-bottom: rem(3) color(red--dark) solid;
        }
    }

    &.slide-down{
        transition: background-color $animation-speed, transform $animation-speed * 3;
        animation-duration: 500ms;
        animation-name: slidedown;
        animation-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
    }

    &.slide-up{
        transition: background-color $animation-speed, transform $animation-speed * 3;
        animation-duration: 500ms;
        animation-name: slideup;
        animation-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
    }

    @include for-specific-size(1680){
        max-width: rem(1680);
        margin: 0 auto;
    }
}


.is-translucent{
    @include for-size('tablet-landscape-up'){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: transparent;

        .hdr{
            background-color: transparent;
            @include maskSquare();

            .show-for-tablet--landscape{
                padding-right: rem(100);
            }

            .hide-for-tablet--landscape{
                display: block;
                position: absolute;
                right: rem(30);
                @include centerY();


                > div{
                    a{
                        display: none;
                    }

                    .hamburger{
                        display: block;
                    }
                }
            }
        }

        .main-nav{
            @include maskSquare();
            .wrapper{
                transform: translateY(-100%);

                 .menu-is-active & {
                    transform: translateY(0%);
                 }
            }

            &.menu--animatable{
                .wrapper{
                    transition: $animation-speed;
                }
            }
        }
    }
}

.location_bar{
    .hdr{
        .show-for-tablet--landscape{
            padding-right: rem(100);
        }

        .hide-for-tablet--landscape{
            display: block;
            position: absolute;
            right: rem(30);
            @include centerY();


            > div{
                a{
                    display: none;
                }

                .hamburger{
                    display: block;
                }
            }
        }
    }

    .main-nav{
        .wrapper{
            transition: clip-path $animation-speed cubic-bezier(0.52, 0.16, 0.24, 1);
            clip-path: polygon(0% 0%, 100% 0%, 100% 48px, 0% 48px);
        }
    }

    .menu-is-active &{
        .main-nav{
            .wrapper{
                flex-wrap: wrap;
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

                > ul{
                    display: flex;
                    flex-basis: 100%;
                    order: 3;
                    justify-content: flex-start;
                }

                > span{
                    order: 1;
                }

                > div{
                    order: 2;
                }



                // >
            }

        }
    }
}

.menu-is-active{
    @include for-size('to-tablet-portrait-only'){
        position: fixed;
        overflow: hidden;
        max-height: 100vh;
        width: 100vw;
    }

    @include for-size('tablet-landscape-up'){
        .is-translucent{
            transition-delay: 130ms;
            background-color: color(white);

            .hdr{
                background-color: color(white);
            }

            .main-nav{
                clip-path: none;
            }
        }
    }
}

.logo{
    display: block;
    width: rem(196);

    svg{
        fill: color(gray--dark);
    }
}

.hamburger{
    padding: 0 rem(10);
    opacity: 1;

    &.is-active, &.is-active:hover{
        opacity: 1;
    }

    &:active, &:focus, &:hover{
        outline: 0;
        opacity: 1;

        .hamburger-inner{
            background-color: color(gray--dark);

            &:after, &:before{
                background-color: color(gray--dark);
            }
        }
    }
}

.hdr{
    background-color: color(white);
    position: relative;
    z-index: 2;
    padding:{
        left: rem(30);
    }
    border-bottom: rem(5) color(red--dark) solid;

    .hide-for-tablet--landscape{
        .d-f{
            a{
                display: block;
                margin-right: rem(10);
                margin-left: rem(30);
                @include svg_wrapper_square(30);
            }
        }
    }

    .show-for-tablet--landscape{
        > a:not(.btn){
            margin-right: rem(40);
            color: color(red--dark);
            text-decoration: none;
            @include navigation_hover_effect(red--dark, -5);
        }
    }

    @include for-size('tablet-landscape-up'){
        border: 0;

        padding:{
            left: rem(32);
            right: rem(30);
        }
    }
}

#podium-bubble{
    right: rem(30) !important;
    .menu-is-active &{
        z-index: 1 !important;
    }
}

#podium-prompt{
    right: rem(30) !important;
    .menu-is-active &{
        z-index: 1 !important;
    }
}
.nearest-location-widget{
    position: fixed;
    bottom: rem(100);
    left: 0;
    z-index: 100;
    width: rem(75);
    background-color: color(gray--dark);
    color: color(white);
    padding: rem(10);
    border-top-right-radius: rem(45);
    border-bottom-right-radius: rem(45);
    box-shadow: transparentize(color(dark), 0.5) rem(3) rem(3) rem(4);
    transition: width $animation-speed * 4 cubic-bezier(0.52, 0.16, 0.24, 1), padding $animation-speed * 4 cubic-bezier(0.52, 0.16, 0.24, 1);
    visibility: hidden;
    pointer-events: none;
    display: none;

    @include for-size('tablet-landscape-up'){
        display: block;
    }

    &.resolved{
        visibility: visible;
        pointer-events: all;
    }

    .d-f{
        align-items: center;
        justify-content: space-between;

        > span{
            width: rem(23);
            svg{
                fill: color(white);
            }
        }
    }

    div.your-local-rnr{
        visibility: hidden;
        opacity: 0;
        transform: translateX(-25%);
        width: calc(100% - 90px);
        margin-left: rem(10);
        white-space: nowrap;
    }

    .modal-close{
        display: none;
        right: initial;
        top: rem(5);
        left: rem(20);
        width: rem(10);
        height: rem(15);

        svg{
            stroke: color(gray);
        }

        &:hover{
            svg{
                stroke: color(white);
            }
        }
    }

    .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(10);
        width: rem(20);
        height: rem(20);

        svg{
            fill: color(white);
            margin-right: rem(-4);
            width: 100%;
        }

        &:hover{
            background-color: transparent;
            svg{
                fill: color(red);
            }
        }
    }

    &.revealed{
        padding-left: rem(70);
        transform: translateX(0);
        width: rem(315);

        div.your-local-rnr{
            overflow: hidden;
            text-overflow: ellipsis;
            visibility: visible;
            opacity: 1;
            transform: translateX(0%);
            transition: opacity $animation-speed * 2 cubic-bezier(0.52, 0.16, 0.24, 1), transform $animation-speed * 2 cubic-bezier(0.52, 0.16, 0.24, 1);
        }

        .modal-close{
            display: block;
        }

        .btn{
            background-color: color(white);

            svg{
                fill: color(red--dark);
            }

            &:hover{
                background-color: color(red--dark);
                svg{
                    fill: color(white);
                }
            }
        }
    }
}
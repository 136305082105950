.staff-single {
  .staff-feature {
    margin: 2rem 0;

    

    & > div {
      border-radius: 1rem;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;

      @include for-specific-size(750){
        flex-direction: row;
      }

      .staff-image {
        img {
          border-radius: 1rem;
        }
      }

      .staff-meta {
        padding: 0 1rem;

        .staff-meta--header {
          display: flex;
          flex-direction: row;
          align-items: center;

          h1 {
            color: #981b1e;
          }


          span {
            padding: 0 1.5rem;
            font-style: italic;
          }
        }

        .staff-meta--contact {
          h2 {
            font-size: 1.5rem;
            margin-bottom: .5rem;
          }
        }
      }
    }
  }
}

.module--touts{
    padding: rem(40)rem(20) rem(40)rem(20);

    @include for-size('tablet-landscape-up'){
        &:not(.no-headline){
            padding: rem(100)rem(30) rem(80)rem(30);
        }
    }

    &.override-reduce-padding{
        padding: rem(40)rem(20) rem(10)rem(20);
    }
}

.touts{
    justify-content: space-between;
    $width: 384;

    max-width: rem($width*3);
    margin: 0 auto;

    &.touts--2{
        max-width: rem($width*2);
    }

    &.touts--1{
        max-width: rem($width);
    }
}

.tout{
    width: 100%;
    margin-bottom: rem(20);

    &:last-child{
        margin-bottom: 0;
    }

    @include for-size('tablet-landscape-up'){
        text-align: center;
        padding: rem(30);
        margin-bottom: 0;

        .touts--2 &{
            width: calc(50% - 60px);
        }

        .touts--3 &{
            width: calc(33% - 60px);
        }

        .touts--4 &{
            width: calc(25% - 60px);
        }

        .touts--5 &{
            width: calc(20% - 60px);
        }

        .touts--6 &{
            width: calc(16.6667% - 60px);
        }
    }
}

.tout--content{
    word-break: keep-all;

    .h-subheadline{
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include for-size('to-tablet-portrait-only'){
        padding: 0 0 0 rem(10);
    }

    p{
        margin-top: rem(5);
    }
}

.tout--icon{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: rem(80);

    .svg--icon-lockout{
        @include for-size('to-tablet-portrait-only'){
            width: rem(47);
        }
    }

    .svg--icon-pay{
        margin-bottom: rem(8);
    }

    .svg--icon-towingservice{
         width: rem(110);
    }

    .svg--icon-deliveryservice{
         width: rem(110);
    }

    .svg--icon-number-1{
        width: rem(35);
    }

    .svg--icon-usa{
        width: rem(105);
    }

    .svg--icon-people-group{
        width: rem(100);
    }

    .svg--icon-heart{
        width: rem(70);
    }

    @include for-size('to-tablet-portrait-only'){
        padding: rem(10) rem(10) rem(10) 0;
    }

    @include for-size('tablet-landscape-up'){
        min-height: rem(75);
        margin-bottom: rem(20);
        align-items: flex-end;
        min-width: rem(125);
    }

    svg{
        width: rem(60);
        fill: color(white);
    }
}

.tout-carousel{
    &:focus, *:focus{
        outline: 0;
    }

    .flickity-viewport{
        width: 100%;
        @include maskSquare();
    }


    .flickity-button{
        background-color: transparent;
        transition: opacity $animation-speed;

        svg{
            fill: color(white);
        }

        &:hover{
            background: transparent;
            opacity: 0.75;

            svg{
                fill: color(white);
            }
        }

        &:disabled{
            opacity: 0.25;

            svg{
               fill: color(white);
            }
        }

        &:active{
            opacity: 1;

            svg{
                fill: color(white);
            }
        }

        &:focus{
            box-shadow: none;

            svg{
                fill: color(white);
            }
        }
    }

    .flickity-prev-next-button{
        @include centerY();
        padding: 0;
        width: rem(40);
        height: rem(40);

        &.previous{
            left: rem(-20);
        }

        &.next{
            right: rem(-20);
        }
    }

    .tout{
        width: calc(25% - 60px);
    }
}

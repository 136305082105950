.module--services-features{
    .service-features{
        margin-top: rem(40);

        &.flickity-enabled{
            .service-feature--panel{
                margin: 0 rem(8);
                width: 68%;
            }
        }

        @include for-size('tablet-landscape-up'){
            margin-top: rem(10);
        }
    }

    .flickity-viewport{
        width: 100%;
        margin-bottom: rem(40);
    }

    .flickity-page-dots{
        bottom: 0;
        @extend %flickity-page-dots;
    }

     .flickity-button{
        @extend %flickity-prev-next-buttons;
    }
}

.service-feature--panel{
    border-radius: rem(30);
    width: 33%;
    min-width: rem(250);

    img{
        width: 50%;
        @include for-size('tablet-landscape-up'){
            max-width: rem(200);
        }
    }
}
.main-nav{
    &.menu--animatable{
        transition: all $animation-speed * 1.5 ease-out;
    }

    ul{
        border-left: 1px color(white) solid;
        border-bottom: 1px color(white) solid;
    }

    li{
        a, button{
            display: block;
            text-decoration: none;
            white-space: nowrap;
            transition: font-size $animation-speed;
        }

        &.has-subnav{
            button{
                position: relative;
                width: 100%;
                text-align: left;
                padding-left: 0;
                outline: none;

                svg{
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: rem(16);
                    fill: color(white);
                    transform: translateY(-50%) rotate(0deg);
                    pointer-events: none;
                    transition: transform $animation-speed;
                }

                &.expanded{
                    svg{
                        transform: translateY(-50%) rotate(90deg);
                    }

                    & + ul{
                        display: block;

                    }
                }
            }

            > ul{
                border-left: 0;
                border-bottom: 0;
                transition: transform 0;
                display: none;

                li{
                    border-bottom: 0;
                }
            }
         }
    }

    .menu-is-active & {
        transform: translateY(0%);
        backdrop-filter: blur(2px);
    }

    .nav-location-header,
    .nav-link a{
        font-weight: 100;
        font-size: rem(13);
        text-transform: capitalize;
        letter-spacing: 0.03em;
    }

    .nav-link{
        max-width: rem(250);
        justify-content: space-between;

        a{
            display: block;
            margin-right: rem(10);
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
    }

    @include for-size('to-tablet-portrait-only'){

        position: fixed;
        top: rem(58);
        left: 0;
        right: 0;
        z-index: 1;
        border-left: rem(20) transparentize(color(gray--medium), 1) solid;
        border-bottom: rem(20) transparentize(color(gray--medium), 1) solid;
        transform: translateY(-103%);
        will-change: transform;
        pointer-events: auto;

        .menu-is-active & {
            &:before{
                content: "";
                position: absolute;
                top: 0;
                left: rem(-20);
                height: 100vh;
                width: 100vw;
                background-color: transparentize(color(gray--medium), 0.1);
                z-index: -1;
            }
        }

        .wrapper{
            overflow: auto;
            max-height: calc(100vh - 104px);
        }

        li{
            background-color: transparent;

            padding: rem(15) rem(30);
            transition: background-color $animation-speed;
            border-bottom: rem(1) color(white) solid;

            a, > div{
                display: grid;
                grid-template-columns: rem(50) 1fr;
                column-gap: rem(10);
                align-items: center;
            }

            &:hover:not(.closest-location){
                background-color: color(red);
                a, button{
                    color: color(white);
                }
            }
        }

        .nav-item-icon{
            display: block;
            @include svg_wrapper_square(32);
        }
    }

    @include for-size('tablet-landscape-up'){
        border: 0;

        .wrapper{
            padding: 0 rem(40) 0 rem(20);
        }

        ul{
            border: 0;
        }

        li{
            padding: 0 rem(15);
            order: initial !important;

            &.has-subnav{
                position: relative;

                > ul{
                    display: block;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    background-color: color(white);
                    box-shadow: 0 rem(2) rem(5) transparentize(color(gray--dark), 0.5);
                    transform: translateY(-100%);
                    transition: transform $animation-speed * 2;

                    li{
                        padding: rem(5) rem(10);
                    }

                    li:not(:last-child){
                        border-bottom: rem(1) color(gray) solid;
                    }

                    a{
                        display: block;
                        padding: rem(10);
                        color: color(gray--dark);
                        background-color: color(white);

                        &:hover, &:focus{
                            color: color(red--dark);
                        }

                        &:focus{
                            outline: 0;
                            background-color: color(white);
                        }
                    }
                }

                &:hover, &:focus, &:focus-within{
                     > ul{
                        top: rem(48);
                        transform: translateY(0%);
                     }
                }
            }
        }

        a, li > button{
            padding:{
                top: rem(12);
                bottom: rem(12);
            }
            font-size: rem(14);
            @include navigation_hover_effect(white, -0.5);
        }
    }

    @include for-specific-size(934){
         a, li > button{
             font-size: rem(15);
        }
    }

    @include for-specific-size(968){
         a, li > button{
             font-size: rem(16);
        }
    }
}

.franchise_bar{
    position: relative;
    z-index: 5;

    @include for-size('to-tablet-portrait-only'){
        & ~ .main-nav{
            transform: translateY(calc(-100% - 69px));

            .menu-is-active &{
                 transform: translateY(calc(0% + 69px));
            }
        }
    }
    @media (min-width: 494px) and (max-width: #{$tablet-portrait-upper-boundary - 1} ) {
        & ~ .main-nav{
            transform: translateY(calc(-100% - 45px));

            .menu-is-active &{
                 transform: translateY(calc(0% + 45px));
            }
        }
    }
}

.nav-sticky{
    position: fixed;
    bottom: rem(10);
    left: rem(10);
    right: rem(10);
    background-color: color(dark);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 500;
    padding: rem(5);
    border-radius: rem(10);

    .menu-is-active &{
        z-index: 1;
    }

    a{
        display: block;
        padding: rem(5);
        overflow: hidden;
        color: transparent;
        @include svg_wrapper_square(33);

        svg{
            transition: fill $animation-speed, stroke $animation-speed;
        }

        span{
            display: contents;
        }

        &:hover{
            svg{
                fill: color(red);
            }
        }
    }

     @include for-size('tablet-landscape-up'){
        display: none;
     }
}

.location-pin{
    white-space: nowrap;
    letter-spacing: 0;

    > span:first-child{
        display: block;
        margin-right: rem(8);
        @include svg_wrapper_square(22);

        svg{
            fill: color(white);
        }
    }
}

.telephone{
    white-space: nowrap;
    letter-spacing: 0;

    span{
        display: block;
        margin-right: rem(8);
        @include svg_wrapper_square(22);

        svg{
            fill: color(white);
        }
    }

    a{
        text-decoration: none;
        font-size: rem(20);
    }
}
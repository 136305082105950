.accordion-trigger{
    min-width: 70%;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    position: relative;

    .arrow{
        position: absolute;
        top: 0;
        right: 0;
        width: rem(20);
        height: rem(20);
        transform: rotate(-90deg);
        transition: transform $animation-speed;
        pointer-events: none;
    }

    &.open{
        .arrow{
             transform: rotate(90deg);
        }
    }
}
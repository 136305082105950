.module--blog-posts{
    padding:{
        top: rem(60);
        bottom: 0;
    };

    @include for-size('tablet-landscape-up'){
        padding:{
            top: rem(60);
            bottom: rem(60);
        };
    }
}


.blog--featured-post{
    .d-f{
        justify-content: flex-end;
        position: relative;
        @include maskSquare();

        .image-panel--photo{
            img{
                width: 100%;
                height: auto;
            }
        }

        @include for-size('tablet-landscape-up'){
            .image-panel--photo{
                position: relative;
                width: 80%;
                img{
                    left: inherit;
                    right: 0;
                    height: 100%;
                }

                &:after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-image: linear-gradient(-90deg, transparent, #fff 70%);
                }
            }
            .image-panel--content{
                left: 0;
                padding:{
                    top: 0;
                    bottom: 0;
                    left: rem(60);
                };
                width: 60%;
                max-width: rem(600);
            }
        }

        @include for-specific-size(1000){
            .image-panel--photo{
                &:after{
                    background-image: linear-gradient(-90deg, transparent, #fff 70%);
                }
            }

            .image-panel--content{
                width: 40%;
            }
        }

        @include for-specific-size(1070){
            .image-panel--photo{
                &:after{
                    background-image: linear-gradient(-90deg, transparent, #fff 75%);
                }
            }

            .image-panel--content{
                width: 36%;
            }
        }

        @include for-specific-size(1240){
            .image-panel--photo{
                &:after{
                    background-image: linear-gradient(-90deg, transparent, #fff 90%);
                }
            }

            .image-panel--content{
                width: 35%;
            }
        }
    }
}

.single-blog, .single-tips-guides {
    .blog--featured-post{
        @include for-size('tablet-landscape-up'){
             .image-panel--content{
                left: 0;
             }
        }
         @include for-size('desktop-up'){
             .image-panel--content{
                left: 10%;
            }
        }
    }
}

.blog-posts{
    margin: {
        left: auto;
        right: auto;
    }

    &:not(.flickity-enabled){
        display: grid;
        grid-gap: rem(10);
        justify-items: stretch;
        grid-template-columns: repeat(auto-fit, minmax(rem(140), 1fr));

        @include for-specific-size(480){
            grid-template-columns: repeat(auto-fit, minmax(rem(170), 1fr));
        }

        @include for-specific-size(570){
            grid-template-columns: repeat(auto-fit, minmax(rem(190), 1fr));
        }

        @include for-size('tablet-portrait-up'){
            grid-template-columns: repeat(auto-fit, minmax(rem(260), 1fr));
            grid-gap: rem(20);
        }

        @include for-specific-size(910){
            grid-template-columns: repeat(auto-fit, minmax(rem(275), 1fr));
        }

        @include for-size('desktop-up'){
            grid-template-columns: repeat(auto-fit, minmax(rem(320), 1fr));
        }
    }

    &.flickity-enabled{
        width: 100%;

        .flickity-slider{
            left: rem(20) !important;
        }

        .post{
            margin: rem(3) rem(10);
            width: 75%;
            // outline: 2px red dashed;
            // outline-offset: 0;
            // overflow: hidden;

            > a{
                display: block;
                // outline: 2px yellow dashed;
                // outline-offset: 1px;
                // padding: rem(20);
            }
        }
    }

    .flickity-viewport{
        width: 100%;
        margin-bottom: rem(40);
    }

    .flickity-page-dots{
        bottom: 0;
        @extend %flickity-page-dots;
    }

     .flickity-button{
        @extend %flickity-prev-next-buttons;
    }

     @include for-size('to-tablet-portrait-only'){
        .h-subheadline{
            font-size: rem(16);
            line-height: 1.1;
        }
    }
}

.post{
    position: relative;
    margin: 0;
    margin-right: rem(10);

    @include for-size('tablet-portrait-up'){
    }

    @include for-size('tablet-landscape-up'){
        margin: 0;

        span.hide-for-tablet--landscape{
            display: inline-block;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity $animation-speed * 1.5;
            will-change: opacity;

            button{
                max-height: 0;
            }
        }

        .h-title--lg{
             transition: font-size $animation-speed * 1.5;
             will-change: font-size;
        }


        &:hover{
            .h-title--lg{
                font-size: rem(30);
            }
            span.hide-for-tablet--landscape{
                visibility: visible;
                opacity: 1;
                max-height: initial;
                transition-delay: $animation-speed;

                button{
                    max-height: initial;
                }
            }
        }
    }
}

.post--image{
    padding-bottom: 150%;
    height: 0;

    img{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 75px);
        object-fit: cover;
        mask-image: linear-gradient(-180deg, rgba(0,0,0,1) 80%, rgba(0,0,0,0));

        @include for-size('desktop-up'){
            mask-image: linear-gradient(-180deg, rgba(0,0,0,1) 85%, rgba(0,0,0,0));
        }
    }
}

.post--image-sm{
    @extend .post--image;
    padding-bottom: 120%;
}

.post--image-sq{
    @extend .post--image-sm;
    padding-bottom: 100%;
}

.post--excerpt{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: color(white);
    padding: rem(10);
    text-align: left;
    min-height: rem(100);

    > *{
        position: relative;
        z-index: 2;
    }

    .btn{
        width: 100%;
    }

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: color(red--dark);
        mix-blend-mode: multiply;
        z-index: 0;
    }

    @include for-size('tablet-portrait-up'){
        padding: rem(20);
    }

    @include for-size('tablet-landscape-up'){
        min-height: rem(135);
    }
}

.blog--entries, .blog--related-entries {
    .post{
        overflow: hidden;

        a{
            .post--image-sq{
                transform: scale(1);
                filter: grayscale(100%);
                transition: transform $animation-speed, filter $animation-speed;
            }

            &:hover{
                .post--image-sq{
                    filter: grayscale(0%);
                    transform: scale(1.1);
                }
            }
        }
    }
}



.program-posts{
    &.flickity-enabled{
        .post--image{
            padding-bottom: 170%;
        }
    }
}

#blog-post-app{
     nav{

        ul{
            flex-wrap: wrap;
        }

        button{
            @extend .h-title--lg;
            text-decoration: none;
            color: color(gray--dark);
            outline: none;
            padding: 0;
            @include navigation_hover_effect(red--dark, -5);
        }
    }

    .blog-posts{
        flex-wrap: wrap;

        .post{
            width: 100%;
        }
    }
}

.single-blog, .single-tips-guides {
    .image-panel{
        .d-f{
            .image-panel--content{
                padding-top: rem(20);
                padding-bottom: rem(20);
            }
        }
    }

    .post--share{
        display: flex;
        justify-content: center;
        margin-top: rem(20);

         @include for-size('tablet-landscape-up'){
            justify-content: flex-start;
         }

        button, a{
            &:not(:last-child){
                margin-right: rem(10);
            }
        }
    }
}

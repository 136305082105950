.module--overlap{
    @include for-size('tablet-landscape-up'){
        position: relative;
        z-index: 5;

        &.module--overlap_dir_top{
             margin-top: -7.7%;
        }
        &.module--overlap_dir_bottom{
             margin-bottom: -8.8%;
        }
    }
}
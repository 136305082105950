.module--video-content{
    .d-f{
        > div{
            &:first-child{
                order: 2;
            }
            &:last-child{
                order: 1;
            }

            @include for-size('tablet-landscape-up'){
                &:first-child{
                    width: calc(50% - 40px);
                    order: 1;
                }

                &:last-child{
                    width: calc(50% - 40px);
                    order: 2;
                }
            }
        }
    }
}
$hdr: 104;
$ftr: 329;

$offset: 0px + ($hdr+$ftr);

.location-finder{

    .location_search{
        margin-bottom: rem(20);
    }

    aside{
        @include for-size('tablet-landscape-up'){
            width: 100%;
            max-width: rem(388);
        }
    }

    #map{
        width: 100%;
        padding-top: 56.25%;

        @include for-size('tablet-landscape-up'){
            padding-top: 0;
            height: calc(100vh - (#{ $hdr }px + 60px));
        }
    }
}

.searchbox{
    padding: rem(20);
    height: rem(185);

    button{
        text-decoration: underline;

        &:hover, &:focus{
            color: color(red);
        }
    }
}

.searchresults{
    @include for-size('tablet-landscape-up'){
        overflow-y: scroll;
        height: calc(100vh - 349px);
    }
}

#location_search{
    width: calc(100% - 40px);
    margin: rem(10) 0 rem(20) 0;
    border: 0;
    padding: rem(5) rem(10);
}

.location--result-box{
    border: rem(3) color(white) solid;
    border-radius: rem(10);
    transition: border-color $animation-speed;

    .contact-info-list{
        margin-bottom: 0;

        @include for-size('tablet-landscape-up'){
            margin-bottom: rem(30);
        }
    }

    .btn{
        // display: none;
        margin-top: rem(20);
        text-align: center;
        max-width: rem(250);
        width: calc(100% - 48px);

        @include for-size('tablet-landscape-up'){
            display: block;
        }
    }

    &.location--result-box-closest{
        border-color: color(red--dark);
    }

    &:hover{
        border-color:color(red--dark);
    }
}


.scrollbar{
    &::-webkit-scrollbar{
        width: rem(20);
        background-color: color(gray--light);
    }

    &::-webkit-scrollbar-thumb{
        border-radius: rem(15);
        border: rem(6) transparent solid;
        background-color: color(red--dark);
        background-clip: content-box;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece{
        background-color: darken(color(gray--light), 5%);
        border: rem(6) color(gray--light)  solid;
    }

    //Firefox
    scrollbar-color: color(gray--light) color(red--dark); /* thumb and track color */
    scrollbar-width: thin;
}
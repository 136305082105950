@use "sass:math";

.module--tabs{
    margin-top: rem(30);
}

.offset-content{
    /* TABS */
    .tabs{
        padding-bottom: rem(20);
        width: calc(100%);
    }

    // shared
    .tab-content--wrapper{
        position: relative;
        z-index: 2;
        overflow: hidden;
        width: 100%;
        transition: background-color $animation-speed;

        .tab-content{
            position: relative;
            z-index: 5;
            padding: rem(40) rem(20) rem(40) rem(20);
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .tab-description{
            width: 100%;

            p{
                width: 75%;
                margin: 0 auto;
            }
        }

        .tab-background{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            mix-blend-mode: multiply;
            filter: grayscale(100%);
            opacity: 0.75;
        }

        &.active{
            background-color: #981b18;

            &:after{
                content: "";
                border:{
                    top: rem(1) transparent solid;
                    left: rem(25) transparent solid;
                    right: rem(25) transparent solid;
                    bottom: rem(25) color(white) solid;
                };
                position: absolute;
                bottom: 0;
                left: 50%;
                z-index: 5;
                transform: translateX(-50%);
            }
        }
    }

    // mobile
    .tabs-for-mobile{
        .tab-content--mobile{
            display: none;

            &.active{
                display: block;
                min-height: rem(175);
            }
        }

        .tabs-for-mobile--buttons{
            max-width: rem(400);
            margin: 0 auto;
            justify-content: center;

            button{
                padding: rem(2) 0;
                margin: 0 rem(10);
                width: 33%;

                &:focus, &:hover, &:active{
                    background-color: color(gray--medium);
                }

                &.active{
                    background-color: color(red--dark);
                }
            }

            .tab-icon{
                display: flex;
                justify-content: center;
                height: rem(47);
                // padding: rem(1) rem(45);

                svg{
                    fill: color(white);
                    width: rem(math.div(45, 1.5));

                    &.svg--icon-pay-buy-monthly{
                        width: rem(math.div(60, 1.5));
                    }

                    &.svg--icon-pay{
                        width: rem(math.div(60, 1.5));
                    }
                }
            }
        }

        @include for-size('tablet-landscape-up'){
            display: none;
        }
    }

    .tabs-for-desktop{
        display: none;
    }

    // desktop
    @include for-size('tablet-landscape-up'){
        .tabs{
             width: calc(90% + 20px);
        }
        .tabs-for-desktop{
            display: flex;
            max-width: rem(1250);
            margin:{
                top: rem(40);
                bottom: rem(80);
                left: auto;
                right: auto;
            };

            button{
                position: relative;
                width: 33%;
                padding: 0;
                margin: rem(10);
                outline: 0;

                img{
                    height: 100%;
                    width: 100%;
                }

                .tab-content--wrapper{
                    height: 100%;
                }

                .tab-icon{
                    background-color: transparent;

                    svg{
                        fill: color(white);
                        width: rem(45);

                        &.svg--icon-pay-buy-monthly{
                            width: rem(60);
                        }

                        &.svg--icon-pay{
                            width: rem(60);
                        }
                    }
                }


                &.active, &:hover, &:active{
                    .tab-content--wrapper{
                        background-color: #981b18;
                    }
                }

                &.active{
                     &:after{
                        content: "";
                        border:{
                            top: rem(1) transparent solid;
                            left: rem(25) transparent solid;
                            right: rem(25) transparent solid;
                            bottom: rem(25) color(white) solid;
                        };
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        z-index: 5;
                        transform: translateX(-50%);
                    }
                }

                .tab-icon{
                    position: relative;
                    z-index: 3;
                    align-items: flex-end;
                    height: rem(47);
                    margin-bottom: rem(30);
                    padding:{
                        top: rem(40);
                        bottom: rem(30);
                    };

                    svg{
                        width: rem(45);

                        &.svg--icon-pay-buy-monthly{
                            width: rem(60);
                        }

                        &.svg--icon-pay{
                            width: rem(60);
                        }
                    }
                }
                .tab-content{
                    padding: 0 rem(20) rem(60) rem(20);
                }

            }
        }
    }

    /* PANELS */
    .tab_section--panel{
        display: none;
        &.active{
            display: block;
        }

        .tab_sections--row{
            clip-path: polygon(0% -11%, 100% -11%, 100% 100%, 0% 100%);
            justify-content: flex-end;

            &.module--overlap_dir_top{
                margin-top: -2.8%
            }

            @include for-size('tablet-landscape-up'){

                .tab_section--photo{
                    img{
                        transform: translateY(-6.2%);
                    }
                }

                &.tab_sections--image-left{
                    flex-direction: row-reverse;
                }

                &.tab_sections--image-right{
                    .tab_section--content{
                        left: 0;
                        right: inherit;
                    }
                }

                &.tab_sections--image-left{
                    .tab_section--content{
                        right: 0;
                        left: inherit;
                    }
                }
            }
        }
    }
}

%flickity-page-dots{
    list-style-type: none;
    padding: 0;
    margin: 0;

    .dot{
        width: rem(8);
        height: rem(8);
        margin: 0 rem(5);
        background-color: color(gray--light);
        border: rem(2) color(gray--dark) solid;
        border-radius: 50%;

        &.is-selected{
            border-color: color(red--dark);
            background-color: color(red--dark);
        }
    }
}

 %flickity-prev-next-buttons{
    background-color: transparent;

    svg{
        fill: color(gray--dark);
        width: 70%;
        height: 70%;

        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.previous{
        svg{
            left: 45%;
        }
    }

    &.next{
        svg{
            left: 55%;
        }
    }

    &:hover{
        background: color(white);

        svg{
            fill: color(red--dark);
        }
    }

    &:disabled{
        opacity: 0.5;

        svg{
            fill: color(white);
        }
    }

    &:active{
        opacity: 1;

        svg{
            fill: color(gray--dark);
        }
    }

    &:focus{
        box-shadow: none;

        svg{
            fill: color(red--dark);
        }
    }
 }
/* ------------------------------------------
Breakpoints
------------------------------------------*/
$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;

/* ------------------------------------------
Setup default values
------------------------------------------*/
$base-font-size: 16;
$base-line-height: 1.4;

$max-content-width: 540;

/* ------------------------------------------
Transitions
------------------------------------------*/
$animation-speed: 200ms;
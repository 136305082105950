#rebates-carousel{
    padding: rem(30) 0 0 0;
    width: 100%;

    .flickity-page-dots{
        left: 0;
        // bottom: 0;
    }

    @include for-size('tablet-landscape-up'){
        margin: rem(30) rem(44);
        width: calc(100% - 88px);
    }
}

.rebate-details{
    padding: rem(20);
    margin: rem(10);
    width: calc(70% - 20px);
    background-color: color(white);
    border-radius: rem(10);
    min-height: rem(200);

    @include for-size('tablet-landscape-up'){
        width: calc(33.334% - 40px);
        background-color: transparent;
    }
}
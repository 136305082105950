.collapsible-lists{

    dd{
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height $animation-speed * 4 cubic-bezier(0.52, 0.16, 0.24, 1);
    }

    dt{
        position: relative;
        padding-right: rem(40);

        &:not(:first-child){
            margin-top: rem(50);
        }

        svg{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: center center;
            width: rem(15);
            height: rem(15);
            transition: transform $animation-speed * 2 cubic-bezier(0.52, 0.16, 0.24, 1);

            @include for-size('tablet-portrait-up'){
                transform: translateY(0);
                position: relative;
                top: 50%;
                left: rem(10);
                right: initial;
            }
        }

        &.open{
            svg{
                transform: rotate(90deg) translateX(-50%);
                transform-origin: center center;

                 @include for-size('tablet-portrait-up'){
                    transform: rotate(90deg);
                 }
            }

            & + dd{
                max-height: rem(500);

                @include for-size('tablet-portrait-up'){
                    max-height: rem(400);
                }

                @include for-size('tablet-landscape-up'){
                    max-height: rem(300);
                }
            }
        }
    }
}
.image-panel{
    .d-f{
        position: relative;
     }

    .image-panel--content{
        text-align: center;
        padding: rem(20);
    }

    .image-panel--video{
        position: relative;
        z-index: 6;
        width: 100%;
    }

    @include for-size('tablet-landscape-up'){
        .d-f{
            // justify-content: flex-end;

            .image-panel--photo{
                width: 100%;

                img{
                    display: block;
                    height: auto;
                    width: 100%;
                }
            }

            .image-panel--video{
                width: calc(50% - 40px);
                padding: rem(40) 0 rem(40) rem(40);
            }

            .image-panel--content{
                position: absolute;
                @include centerY();
                z-index: 4;
                text-align: left;
                padding: rem(80);
                width: 40%;
                max-width: rem(430);
            }
        }
    }
}
/*
 * HTML
 ========================================================================== */
html{
    scroll-behavior: smooth;
}

html, body{
    @extend %font-myriad-pro;
    -webkit-font-smoothing: antialiased;
}

body{
    background-color: color(white);
    color: color(gray--dark);
    padding-top: rem(59);

    &.has_franchise_bar{
        padding-top: rem(103);
    }

    @include for-size('tablet-landscape-up'){
        padding-top: rem(104);
        padding-bottom: 0;

        &.has-sticky-nav{
            padding-top: 0;
        }

        &.reduced-padding{
            padding-top: rem(54);
        }

        &.has_franchise_bar{
            padding-top: rem(150);
        }
    }

    @include for-specific-size(1680){
        background-color: color(gray);
    }
}

strong, b{
    @extend %font-myriad-pro-bold;
}

em, i{
    @extend %font-myriad-pro;
    font-style: italic;
}

p:empty{
    display: none;
}

code{
    display: inline-block;
    font:{
        family: monospace;
        size: rem(16);
    };
    border: 1px color(gray) solid;
    padding: rem(15);
    margin: rem(20) 0;
}

main{
    overflow: hidden;

    @include for-specific-size(1680){
        background-color: color(white);
        max-width: rem(1680);
        margin: 0 auto;
    }
}

a{
     transition: color $animation-speed;
}

button{
    border: 0;
    background-color: transparent;
    -webkit-appereance: none;
    cursor: pointer;
}

hr{
    border: 0;
    outline: 0;
    height: rem(1);
    background-color: color(white);
}

.debug{
    outline: 1px color(red) dashed;
    padding: rem(5);
    margin: rem(5);
}

/*
 * Remove text-shadow in selection highlight
 ======================================================= */
::-moz-selection {
    background-color: transparentize(color(red), 0.75);
    text-shadow: none;
}

::selection {
    background-color: transparentize(color(red), 0.75);
    text-shadow: none;
}

/*
* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
* element visibility
========================================================================== */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden;
}


/*
 * Hide for screen sizes
 ========================================================================== */
.hide-for-desktop{
    display: block;

    @include for-size('desktop-up'){
        display: none;
    }
}

.show-for-desktop{
    display: none;

    @include for-size('desktop-up'){
        display: block;
    }
}

.hide-for-tablet{
    display: block;

    @include for-size('tablet-portrait-up'){
        display: none;
    }
}

.show-for-tablet{
    display: none;

    @include for-size('tablet-portrait-up'){
        display: block;
    }
}
.hide-for-tablet--landscape{
    display: block;

    @include for-size('tablet-landscape-up'){
        display: none;
    }
}

.show-for-tablet--landscape{
    display: none;

    @include for-size('tablet-landscape-up'){
        display: block;
    }
}

// FLEX
.hide-for-tablet--landscape-flex{
    display: flex;

    @include for-size('tablet-landscape-up'){
        display: none;
    }
}

.show-for-tablet--landscape-flex{
    display: none;

    @include for-size('tablet-landscape-up'){
        display: flex;
    }
}

.hide-for-mobile{
    display: block;

    @include for-size('phone-only'){
        display: none;
    }
}

.show-for-mobile{
    display: none;

    @include for-size('phone-only'){
        display: block;
    }
}


/*
 * Alignment
 ========================================================================== */
.align--center, .aligncenter{ text-align: center; }
.align--left, .alignleft{ text-align: left; }
.align--right, .alignright{ text-align: right; }

/*
 * Padding & Margins
 ========================================================================== */
 $modifier: 10 !default;

 @for $m from 0 through 10{
    .p-#{ $modifier * $m }{ padding: rem( $modifier * $m ); }
    .p-l-#{ $modifier * $m }{ padding-left: rem( $modifier * $m ); }
    .p-r-#{ $modifier * $m }{ padding-right: rem( $modifier * $m ); }
    .p-t-#{ $modifier * $m }{ padding-top: rem( $modifier * $m ); }
    .p-b-#{ $modifier * $m }{ padding-bottom: rem( $modifier * $m ); }

    @include for-size('phone-only'){
        .p-#{ $modifier * $m }--mobile{ padding: rem( $modifier * $m); }
        .p-l-#{ $modifier * $m }--mobile{ padding-left: rem( $modifier *  $m); }
        .p-r-#{ $modifier * $m }--mobile{ padding-right: rem( $modifier *  $m); }
        .p-t-#{ $modifier * $m }--mobile{ padding-top: rem( $modifier *  $m); }
        .p-b-#{ $modifier * $m }--mobile{ padding-bottom: rem( $modifier *  $m); }
    }

    @include for-size('tablet-portrait-up'){
        .p-#{ $modifier * $m }--portrait{ padding: rem( $modifier * $m); }
        .p-l-#{ $modifier * $m }--portrait{ padding-left: rem( $modifier *  $m); }
        .p-r-#{ $modifier * $m }--portrait{ padding-right: rem( $modifier *  $m); }
        .p-t-#{ $modifier * $m }--portrait{ padding-top: rem( $modifier *  $m); }
        .p-b-#{ $modifier * $m }--portrait{ padding-bottom: rem( $modifier *  $m); }
    }

    @include for-size('tablet-landscape-up'){
        .p-#{ $modifier * $m }--landscape{ padding: rem( $modifier * $m); }
        .p-l-#{ $modifier * $m }--landscape{ padding-left: rem( $modifier *  $m); }
        .p-r-#{ $modifier * $m }--landscape{ padding-right: rem( $modifier *  $m); }
        .p-t-#{ $modifier * $m }--landscape{ padding-top: rem( $modifier *  $m); }
        .p-b-#{ $modifier * $m }--landscape{ padding-bottom: rem( $modifier *  $m); }
    }

    @include for-size('desktop-up'){
        .p-#{ $modifier * $m }--desktop{ padding: rem( $modifier * $m); }
        .p-l-#{ $modifier * $m }--desktop{ padding-left: rem( $modifier *  $m); }
        .p-r-#{ $modifier * $m }--desktop{ padding-right: rem( $modifier *  $m); }
        .p-t-#{ $modifier * $m }--desktop{ padding-top: rem( $modifier *  $m); }
        .p-b-#{ $modifier * $m }--desktop{ padding-bottom: rem( $modifier *  $m); }
    }

    @include for-size('big-desktop-up'){
        .p-#{ $modifier * $m }--desktopXL{ padding: rem( $modifier * $m); }
        .p-l-#{ $modifier * $m }--desktopXL{ padding-left: rem( $modifier *  $m); }
        .p-r-#{ $modifier * $m }--desktopXL{ padding-right: rem( $modifier *  $m); }
        .p-t-#{ $modifier * $m }--desktopXL{ padding-top: rem( $modifier *  $m); }
        .p-b-#{ $modifier * $m }--desktopXL{ padding-bottom: rem( $modifier *  $m); }
    }
 }

 @for $m from 0 through 10{
    .m-#{ $modifier * $m }{ margin: rem( $modifier * $m ); }
    .m-l-#{ $modifier * $m }{ margin-left: rem( $modifier * $m ); }
    .m-r-#{ $modifier * $m }{ margin-right: rem( $modifier * $m ); }
    .m-t-#{ $modifier * $m }{ margin-top: rem( $modifier * $m ); }
    .m-b-#{ $modifier * $m }{ margin-bottom: rem( $modifier * $m ); }

    @include for-size('phone-only'){
        .m-#{ $modifier * $m }--mobile{ margin: rem( $modifier * $m); }
        .m-l-#{ $modifier * $m }--mobile{ margin-left: rem( $modifier *  $m); }
        .m-r-#{ $modifier * $m }--mobile{ margin-right: rem( $modifier *  $m); }
        .m-t-#{ $modifier * $m }--mobile{ margin-top: rem( $modifier *  $m); }
        .m-b-#{ $modifier * $m }--mobile{ margin-bottom: rem( $modifier *  $m); }
    }

    @include for-size('tablet-portrait-up'){
        .m-#{ $modifier * $m }--portrait{ margin: rem( $modifier * $m); }
        .m-l-#{ $modifier * $m }--portrait{ margin-left: rem( $modifier *  $m); }
        .m-r-#{ $modifier * $m }--portrait{ margin-right: rem( $modifier *  $m); }
        .m-t-#{ $modifier * $m }--portrait{ margin-top: rem( $modifier *  $m); }
        .m-b-#{ $modifier * $m }--portrait{ margin-bottom: rem( $modifier *  $m); }
    }

    @include for-size('tablet-landscape-up'){
        .m-#{ $modifier * $m }--landscape{ margin: rem( $modifier * $m); }
        .m-l-#{ $modifier * $m }--landscape{ margin-left: rem( $modifier *  $m); }
        .m-r-#{ $modifier * $m }--landscape{ margin-right: rem( $modifier *  $m); }
        .m-t-#{ $modifier * $m }--landscape{ margin-top: rem( $modifier *  $m); }
        .m-b-#{ $modifier * $m }--landscape{ margin-bottom: rem( $modifier *  $m); }
    }

    @include for-size('desktop-up'){
        .m-#{ $modifier * $m }--desktop{ margin: rem( $modifier * $m); }
        .m-l-#{ $modifier * $m }--desktop{ margin-left: rem( $modifier *  $m); }
        .m-r-#{ $modifier * $m }--desktop{ margin-right: rem( $modifier *  $m); }
        .m-t-#{ $modifier * $m }--desktop{ margin-top: rem( $modifier *  $m); }
        .m-b-#{ $modifier * $m }--desktop{ margin-bottom: rem( $modifier *  $m); }
    }

    @include for-size('big-desktop-up'){
        .m-#{ $modifier * $m }--desktopXL{ margin: rem( $modifier * $m); }
        .m-l-#{ $modifier * $m }--desktopXL{ margin-left: rem( $modifier *  $m); }
        .m-r-#{ $modifier * $m }--desktopXL{ margin-right: rem( $modifier *  $m); }
        .m-t-#{ $modifier * $m }--desktopXL{ margin-top: rem( $modifier *  $m); }
        .m-b-#{ $modifier * $m }--desktopXL{ margin-bottom: rem( $modifier *  $m); }
    }
 }

.m-auto{ margin: auto; }
.m-lr-auto{
    margin: {
        left: auto;
        right: auto;
    };
}

/*
 * Responsive
 ========================================================================== */
 .img-fluid{
    width: 100%;
    height: auto;
 }

 .img-fluid--max-width{
    max-width: 100%;
    height: auto;
 }


/*
 * Flexbox
 ========================================================================== */
.flx-row{ flex-direction: row; }
.flx-col{ flex-direction: column; }
.flx-jcc{ justify-content: center; }
.flx-jsa{ justify-content: space-around; }
.flx-jsb{ justify-content: space-between; }
.flx-jfs{ justify-content: flex-start; }
.flx-jfe{ justify-content: flex-end; }
.flx-aic{ align-items: center; }

@include for-size('phone-only'){
    .flx-row--mobile{ flex-direction: row; }
    .flx-col--mobile{ flex-direction: column; }
    .flx-jcc--mobile{ justify-content: center; }
    .flx-jsa--mobile{ justify-content: space-around; }
    .flx-jsb--mobile{ justify-content: space-between; }
    .flx-jfs--mobile{ justify-content: flex-start; }
    .flx-jfe--mobile{ justify-content: flex-end; }
    .flx-aic--mobile{ align-items: center; }
}
@include for-size('tablet-portrait-up'){
    .flx-row--portrait{ flex-direction: row; }
    .flx-col--portrait{ flex-direction: column; }
    .flx-jcc--portrait{ justify-content: center; }
    .flx-jsa--portrait{ justify-content: space-around; }
    .flx-jsb--portrait{ justify-content: space-between; }
    .flx-jfs--portrait{ justify-content: flex-start; }
    .flx-jfe--portrait{ justify-content: flex-end; }
    .flx-aic--portrait{ align-items: center; }
}
@include for-size('tablet-landscape-up'){
    .flx-row--landscape{ flex-direction: row; }
    .flx-col--landscape{ flex-direction: column; }
    .flx-jcc--landscape{ justify-content: center; }
    .flx-jsa--landscape{ justify-content: space-around; }
    .flx-jsb--landscape{ justify-content: space-between; }
    .flx-jfs--landscape{ justify-content: flex-start; }
    .flx-jfe--landscape{ justify-content: flex-end; }
    .flx-aic--landscape{ align-items: center; }
}
@include for-size('desktop-up'){
    .flx-row--desktop{ flex-direction: row; }
    .flx-col--desktop{ flex-direction: column; }
    .flx-jcc--desktop{ justify-content: center; }
    .flx-jsa--desktop{ justify-content: space-around; }
    .flx-jsb--desktop{ justify-content: space-between; }
    .flx-jfs--desktop{ justify-content: flex-start; }
    .flx-jfe--desktop{ justify-content: flex-end; }
    .flx-aic--desktop{ align-items: center; }
}
@include for-size('big-desktop-up'){
    .flx-row--desktopXL{ flex-direction: row; }
    .flx-col--desktopXL{ flex-direction: column; }
    .flx-jcc--desktopXL{ justify-content: center; }
    .flx-jsa--desktopXL{ justify-content: space-around; }
    .flx-jsb--desktopXL{ justify-content: space-between; }
    .flx-jfs--desktopXL{ justify-content: flex-start; }
    .flx-jfe--desktopXL{ justify-content: flex-end; }
    .flx-aic--desktopXL{ align-items: center; }

}

/*
 * Flex Orders
 ========================================================================== */
 @for $m from 0 through 10{
    .flx-order-#{ $m }{ order: $m }

    @include for-size('phone-only'){
        .flx-order-#{ $m }--mobile{ order: $m }
    }

    @include for-size('tablet-portrait-up'){
        .flx-order-#{ $m }--portrait{ order: $m }
    }

    @include for-size('tablet-landscape-up'){
        .flx-order-#{ $m }--landscape{ order: $m }
    }

    @include for-size('desktop-up'){
        .flx-order-#{ $m }--desktop{ order: $m }
    }

    @include for-size('big-desktop-up'){
        .flx-order-#{ $m }--desktopXL{ order: $m }
    }

 }




/*
 * Display
 ========================================================================== */
.d-b{ display: block; }
.d-i{ display: inline; }
.d-ib{ display: inline-block; }
.d-if{ display: inline-flex; }
.d-f{ display: flex }
.d-g{ display: grid }
.d-n{ display: none }

@include for-size('phone-only'){
    .d-b--mobile{ display: block; }
    .d-i--mobile{ display: inline; }
    .d-ib--mobile{ display: inline-block; }
    .d-if--mobile{ display: inline-flex; }
    .d-f--mobile{ display: flex }
    .d-n--mobile{ display: none }
}
@include for-size('tablet-portrait-up'){
    .d-b--portrait{ display: block; }
    .d-i--portrait{ display: inline; }
    .d-ib--portrait{ display: inline-block; }
    .d-if--portrait{ display: inline-flex; }
    .d-f--portrait{ display: flex }
    .d-g--portrait{ display: grid }
    .d-n--portrait{ display: none }
}
@include for-size('tablet-landscape-up'){
    .d-b--landscape{ display: block; }
    .d-i--landscape{ display: inline; }
    .d-ib--landscape{ display: inline-block; }
    .d-if--landscape{ display: inline-flex; }
    .d-f--landscape{ display: flex }
    .d-g--landscape{ display: grid }
    .d-n--landscape{ display: none }
}
@include for-size('desktop-up'){
    .d-b--desktop{ display: block; }
    .d-i--desktop{ display: inline; }
    .d-ib--desktop{ display: inline-block; }
    .d-if--desktop{ display: inline-flex; }
    .d-f--desktop{ display: flex }
    .d-g--desktop{ display: grid }
    .d-n--desktop{ display: none }
}
@include for-size('big-desktop-up'){
    .d-b--desktopXL{ display: block; }
    .d-i--desktopXL{ display: inline; }
    .d-ib--desktopXL{ display: inline-block; }
    .d-if--desktopXL{ display: inline-flex; }
    .d-f--desktopXL{ display: flex }
    .d-n--desktopXL{ display: none }
}

/*
 * Positions
 ========================================================================== */
 .p-a{ position: absolute; }
 .p-r{ position: relative; }
 .p-f{ position: fixed; }

 @include for-size('phone-only'){
    .p-a--mobile{ position: absolute; }
    .p-r--mobile{ position: relative; }
    .p-f--mobile{ position: fixed; }
 }

 @include for-size('tablet-portrait-up'){
     .p-a--portrait{ position: absolute; }
     .p-r--portrait{ position: relative; }
     .p-f--portrait{ position: fixed; }
 }

 @include for-size('tablet-landscape-up'){
     .p-a--landscape{ position: absolute; }
     .p-r--landscape{ position: relative; }
     .p-f--landscape{ position: fixed; }
 }

 @include for-size('desktop-up'){
     .p-a--desktop{ position: absolute; }
     .p-r--desktop{ position: relative; }
     .p-f--desktop{ position: fixed; }
 }

 @include for-size('big-desktop-up'){
     .p-a--desktopXL{ position: absolute; }
     .p-r--desktopXL{ position: relative; }
     .p-f--desktopXL{ position: fixed; }
 }


/*
 * Helpers
 ========================================================================== */
 .txt-dn{
    text-decoration: none;
 }

 .nowrap{
    white-space: nowrap;
 }

 .truncate-line{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
 }

 .truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
 }


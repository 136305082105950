.module--expandable_view{

    .showMore{
        border: rem(1) color(gray--dark) solid;
        span{
            pointer-events: none;

            &:first-child{
                display: inline;
            }
            &:last-child{
                display: none;
            }
        }

        &.expanded{
            background-color: transparent;
            color: color(gray--dark);


            span{
                &:first-child{
                    display: none;
                }
                &:last-child{
                    display: inline;
                }
            }

            &:hover, &:focus, &:active{
                color: color(red--dark);
                border-color: color(red--dark);
            }
        }
    }

    .expanded-content{
        overflow: hidden;
        transition: height $animation-speed * 3;
    }

    @include for-size('tablet-landscape-up'){
        .module--photo_pos_left{
            order: 1;
        }

        .module--photo_pos_right{
            order: 2;
        }

        .module--text_pos_left{
            order: 1;
        }

        .module--text_pos_right{
            order: 2;
        }

        .d-f{
            &:not(.expanded){
                align-items: center;
            }
            .expanded{
                align-items: flex-start;
            }
        }

        &.image-panel{
            .d-f{
                .image-panel--photo{
                    padding: rem(60) rem(40);
                    width: calc(40% - 40px);
                    // outline: 1px red dashed;
                }
                .image-panel--content{
                    position: static;
                    top: initial;
                    width: calc(60% - 40px);
                    transform: none;
                    padding: rem(60) rem(40);
                    // outline: 1px red dashed;
                }
                .image-panel--content-fullwidth{
                    position: static;
                    top: initial;
                    padding: rem(60) rem(40);
                }
            }
        }
    }
}
.module--need-want{
    max-width: rem(275);
    margin: rem(85) auto rem(85) auto;
    padding: 0 rem(40);

    .d-f{
        > div{
            position: relative;

             img{
                display: block;
                position: absolute;
                left: 50%;
                width: 110%;
                height: auto;
            }

            &:first-child{
                border-top-left-radius: rem(20);
                border-top-right-radius: rem(20);
                padding: rem(40) rem(40) 75% rem(40);
                clip-path: polygon(-7% 0%, 107% 0%, 107% 100%, -7% 100%);

                img{
                    bottom: 0;
                    transform: translate(-50%, 50%);
                }
            }

            &:last-child{
                border-bottom-left-radius: rem(20);
                border-bottom-right-radius: rem(20);
                padding: 65% rem(40) rem(40) rem(40);
                clip-path: polygon(-7% 0%, 107% 0%, 107% 100%, -7% 100%);

                img{
                    top: 0;
                    transform: translate(-50%, -50%);
                }

                picture{
                    order: 1;
                }
            }

            &:hover, &:focus{

            }

        }
    }

    @include for-size('tablet-portrait-up'){
        max-width: rem(450);
    }

    @include for-size('tablet-landscape-up'){
        max-width: rem(1200);
        width: 90%;

        .h-headline{
            font-size: rem(35);
        }

         .d-f{
            align-items: center;

            > div{
                justify-content: center;
                width: 50%;
                min-height: rem(250);
                padding-bottom: rem(40);


                 img{
                    top: 50%;
                    width: auto;
                    height: 119%;
                }

                &:first-child{
                    border-top-left-radius: rem(20);
                    border-top-right-radius: initial;
                    border-bottom-left-radius: rem(20);
                    padding: rem(40) rem(208) rem(40) rem(40);
                    clip-path: polygon(0% -10%, 100% -10%, 100% 110%, 0 110%);

                    img{
                        top: 50%;
                        bottom: initial;
                        right: 0;
                        left: initial;
                        transform-origin: right top;
                        transform: rotate(0deg) translate(50%, -50%);
                    }
                }

                &:last-child{
                    border-top-right-radius: rem(20);
                    border-bottom-left-radius: initial;
                    border-bottom-right-radius: rem(20);
                    padding: rem(40) rem(40) rem(40) rem(208);
                    clip-path: polygon(0% -10%, 100% -10%, 100% 110%, 0 110%);

                    img{
                        top: 50%;
                        bottom: initial;
                        left: 0;
                        transform-origin: left top;
                        transform: rotate(0deg)  translate(-50%, -50%);
                    }

                    picture{
                        order: initial;
                    }
                }
            }
         }
    }

    @include for-size('desktop-up'){
        .h-headline{
            font-size: rem(50);
        }
    }
}
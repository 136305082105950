.module--our-services{
    background-color: color(gray--light);
    margin:{
        top: rem(60);
        bottom: rem(40);
    };
    padding:{
        bottom: rem(60);
    }


    .d-f{
        justify-content: flex-start;

        @include for-size('desktop-up'){
            .image-panel--photo{
                img{
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    @include for-size('tablet-landscape-up'){
        background-color: color(white);
        margin-bottom: 0;

        &.image-panel{
            .d-f{
                .image-panel--photo{
                    margin-top: -3%;

                    img{
                        width: 150%;
                        margin-left: -25%;
                    }
                }

                .image-panel--content{
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    @include for-size('desktop-up'){
         &.image-panel{
            .d-f{
                .image-panel--photo{
                    img{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.services-carousel{
    position: relative;
    width: calc(100% + 40px);
    margin:{
        left: rem(-20);
        right: rem(-20);
        top: rem(20);
        bottom: rem(20);
    };

    .flickity-button{
        display: none;
    }

     @include for-size('tablet-landscape-up'){
        width: 100%;
        margin:{
            left: auto;
            right: auto;
        };
        .flickity-button{
            display: block;
        }
     }

    &:focus, *:focus{
        outline: 0;
    }

    .flickity-viewport{
        @include maskSquare();
    }


    .flickity-button{
        background-color: transparent;

        svg{
            fill: color(gray--medium);
        }

        &:hover{
            background: transparent;

            svg{
                fill: color(gray--dark);
            }
        }

        &:disabled{
            opacity: 1;

            svg{
                fill: color(gray);
            }
        }

        &:active{
            opacity: 1;

            svg{
                fill: color(gray--dark);
            }
        }

        &:focus{
            box-shadow: none;

            svg{
                fill: color(red--dark);
            }
        }
    }

    .flickity-prev-next-button{
        position: absolute;
        @include centerY();
        padding: 0;
        width: rem(40);
        height: rem(40);


        &.previous{
            left: rem(-40);
        }

        &.next{
            right: rem(-40);
        }
    }
}

.services-carousel--item{
    color: color(red--dark);
    text-decoration: none;
    opacity: 0.5;
    text-align: center;
    padding: rem(10);
    width: calc(33% - 20px);

    h3{
        font-size: rem(14);
    }

    &.is-selected{
        opacity: 1;

        .services-carousel--icon{
            svg{
                width: rem(65);
                height: rem(65);
            }
        }
    }

    &:hover{
       color: color(red--dark);
    }
}

.services-carousel--icon{
    margin: 0 auto;

    svg{
        width: rem(50);
        height: rem(50);
        fill: color(red--dark);
        transition: height $animation-speed, width $animation-speed;
    }
}
@use "sass:math";

/* ------------------------------------------
Media Query based on device range
https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.4tufucigb

------------------------------------------*/
@mixin for-size($range) {
	@if $range == phone-only {
		@media (max-width: #{$phone-upper-boundary - 1}) { @content; }
	}
	@else if $range == to-tablet-portrait-only {
		@media (max-width: #{$tablet-portrait-upper-boundary - 1}) { @content; }
	}
    @else if $range == tablet-portrait-up {
        @media (min-width: $phone-upper-boundary) { @content; }
    }
    @else if $range == to-tablet-landscape-only {
        @media (max-width: #{$tablet-landscape-upper-boundary - 1}) { @content; }
    }
	@else if $range == tablet-landscape-up {
		@media (min-width: $tablet-portrait-upper-boundary) { @content; }
	}
	@else if $range == desktop-up {
		@media (min-width: $tablet-landscape-upper-boundary) { @content; }
	}
	@else if $range == big-desktop-up {
		@media (min-width: $desktop-upper-boundary) { @content; }
	}
}

@mixin for-specific-size($pixels) {
    @media (min-width: rem($pixels) ) { @content; }
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    /* Chrome/Opera/Safari */
    ::-webkit-input-placeholder { color: $color !important;  }

    /* Firefox 19+ */
    ::-moz-placeholder { color: $color !important; }

    /* IE 10+ */
    :-ms-input-placeholder { color: $color !important;  }

    /* Firefox 18- */
    :-moz-placeholder { color: $color !important;  }
}

@mixin svg_wrapper_square($size){
    width: rem($size);
    height: rem($size);

    svg{
        width: 100%;
        height: 100%;
    }
}

@mixin aspectRatio(){
    /* <div class="aspectRatio"> <div class="inner-wrapper"></div> </div> */

    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
    }
    > .inner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin aspectRatioSize($w, $h){
    &:before{
        padding-top: math.div($h, $w) * 100%;
    }
}

@mixin maskSquare(){
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/* ------------------------------------------
Positioning
------------------------------------------ */
@mixin centerY(){
    top: 50%;
    transform: translateY(-50%);
}

@mixin centerX(){
    left: 50%;
    transform: translateX(-50%);
}

@mixin navigation_hover_effect($color, $bottom){
    position: relative;

    &:after{
        content: "";
        position: absolute;
        bottom: rem($bottom);
        left: 0;
        width: 100%;
        background-color: color($color);
        height: rem(3);
        pointer-events: none;
        transform: scaleX(0);
        transform-origin: left bottom;
        transition: transform $animation-speed;
    }

    &:hover, &:active, &:focus, &.cur{
        color: color($color);

        &:after{
            transform: scaleX(1);
        }
    }
}

@mixin sliderStyles(){
    border: 0;
    height: rem(23);
    width: rem(23);
    border-radius: 50%;
    background: color(red--dark);
    cursor: pointer;
    // margin-top: -14px;
}
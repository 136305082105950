/*
 * Font Family
 ========================================================================== */
$font-myriad-pro: myriad-pro, Helvetica, Arial, sans-serif;

%font-myriad-pro{
    font:{
        family: $font-myriad-pro;
        style: normal;
        weight: 400;
    };
}

%font-myriad-pro-semibbold{
    font:{
        family: $font-myriad-pro;
        style: normal;
        weight: 600;
    };
}

%font-myriad-pro-bold{
    font:{
        family: $font-myriad-pro;
        style: normal;
        weight: 700;
    };
}


/* ------------------------------------------
Headers
------------------------------------------*/
#{headings(1,6)} {
	line-height: 1.4;
	margin: 0;
}

.h-1, .h-headline{
    @extend %font-myriad-pro-bold;
    font-size: rem(30);
    line-height: leadingToEm(50, 54);
    text-transform: uppercase;

}

.h-2, .h-subheadline{
    @extend %font-myriad-pro-semibbold;
    font-size: rem(30);
    line-height: leadingToEm(30, 36);
}

.h-3, .h-title--lg{
    @extend %font-myriad-pro-bold;
    font-size: rem(20);
    line-height: leadingToEm(20, 28);
    text-transform: uppercase;
}

.h-4, .h-title{
    @extend %font-myriad-pro-bold;
    font-size: rem(16);
    line-height: leadingToEm(16, 24);
    letter-spacing: trackingToEm(80);
    text-transform: uppercase;
}

.h-5, .h-title--sm{
    @extend %font-myriad-pro-bold;
    font-size: rem(14);
    line-height: leadingToEm(16, 20);
    letter-spacing: trackingToEm(80);
    text-transform: uppercase;
}

/* ------------------------------------------
Body Text
------------------------------------------*/
p, li{
    line-height: 1.4;
}

.text--default{
    @extend %font-myriad-pro;
    font-size: rem(16);
    line-height: leadingToEm(16, 25);
}

.text--larger{
    @extend %font-myriad-pro;
    font-size: rem(20);
    line-height: leadingToEm(20, 28);
}

.text--medium{
    @extend %font-myriad-pro;
    font-size: rem(14);
    line-height: leadingToEm(16, 25);
}

.text--smaller{
    @extend %font-myriad-pro;
    font-size: rem(12);
    line-height: leadingToEm(16, 25);
}

.text--uc{
    text-transform: uppercase;
}

.text--lc{
    text-transform: lowercase;
}

.text--cp{
    text-transform: capitalize;
}

.text--link, a{
    color: color(gray--dark);

    &:hover, &:focus, &:active{
        color: color(red);
    }
}

.text--directions{
    color: color(red--dark);
    font-weight: 700;
    text-transform: uppercase;
    margin-left: rem(5);
}

.quote{}

@include for-size('tablet-landscape-up'){
    .h-1, .h-headline{
        font-size: rem(50);
    }

    .h-2, .h-subheadline{
        font-size: rem(30);
    }

    .h-3, .h-title--lg{
        font-size: rem(20);
    }

    .h-4, .h-title{
        font-size: rem(16);
    }

    .h-5, .h-title--sm{
        font-size: rem(14);
    }

    .text--default{
        font-size: rem(16);
    }

    .text--larger{
        font-size: rem(20);
    }

    .text--smaller{
        font-size: rem(12);
    }
}



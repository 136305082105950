.franchisee-archive {
  .franchisee-posts {
    padding-top: 1.5rem;

    &>.row {
      gap: 1rem 0;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .content-franchisee {
      &>div {
        border-radius: 1rem;
        padding: 0.5rem 1.5rem;
      }

      a {
        text-decoration: none;

        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }

      h2 {
        font-size: 1.25rem;
      }

      .owner-info {
        padding-top: 0.75rem;

        .contact-info {
          display: flex;

          div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex: 1;

            a {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}

.franchisee-single {
  .franchisee-info {
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto;
    width: calc(100% - 3rem);
    padding: 0;
    margin: 2rem auto;

    @media only screen and (min-width: 700px) {
      grid-template-columns: 35% auto;
      width: auto;
    }

    .franchisee-info--content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h1 {
        margin-bottom: 1rem;
        font-size: 2.5rem;
      }
    }

    .info {
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .contact-info {
        h2 {
          margin-bottom: .5rem;
          border-bottom: 2px solid #981b1e;
        }

        h3 {
          font-size: 1.25rem;
        }
      }
    }

    .locations-map {
      width: 100%;
      border-radius: 0 0 1rem 1rem;
      height: 16rem;

      @media only screen and (min-width: 700px) {
        border-radius: 0 1rem 1rem 0;
        height: 24rem;
      }


    }
  }

  .franchisee-locations {
    padding: 1rem 0;

    .slider-container {
      padding: 1rem 3rem;

      .location {
        padding: 0 .5rem;

        .location-content {
          background: white;
          border-radius: 1rem;
          padding: 1rem;
          text-align: center;

          h1 {
            font-size: 1.5rem;
          }
        }
      }

      .view-all-button {
        padding-top: 2rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

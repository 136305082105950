//  Placeholder styles
@include placeholder( color('gray--dark') );

@mixin formFieldStyles(){
    border:{
        color: color(white);
        width: 0;
        radius: rem(10);
    };
    box-shadow: none;
    font:{
        family: $font-myriad-pro !important;
        style: normal !important;
        weight: 500 !important;
    };

    &:-internal-autofill-selected, &:-webkit-autofill {
        background-color: color(white) !important;
    }
}

input[type="search"]{
    @include formFieldStyles();
    padding: rem(8);
    border: rem(1) color(gray) solid;
}

input[type="range"]{
    -webkit-appearance: none;
    width: 100%;
    height: rem(4);
    border-radius: 5px;
    margin: rem(20) 0;
    background-color: color(gray--dark);

    /* Special styling for WebKit/Blink */
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        @include sliderStyles();
    }

    /* All the same stuff for Firefox */
    &::-moz-range-thumb{
        @include sliderStyles();
    }

    /* All the same stuff for Firefox */
    &::-ms-thumb {
        @include sliderStyles();
    }

    &::-ms-track{
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &:focus{
        outline: none;
    }

}

select{
    -webkit-appearance: none;
}


// Footer Email Sign Up Form
.frm_forms .email-sign-up-form{
    margin-top: rem(2);

    fieldset {
        border: none;
        padding: 0;
    }

    .newsletter-email-field {

        .field-background {
            padding: rem(5) rem(5) rem(5) rem(20);
            border-radius: rem(25);
            background-color: color(white);
        }

        input[type="email"]{
            border: 0;
            width: calc(100% - 20px);
            margin-top: rem(2);
            padding: rem(5);
            padding-left: rem(5) !important;
            border-radius: 0;

            &:active, &:focus{
                outline-color: color(gray);
                outline-width: rem(1);
            }
        }

        .frm_error {
            font:{
                family: $font-myriad-pro !important;
                style: normal !important;
                weight: 500 !important;
                size: rem(10);
            };
            color: color(white);
            margin-top: rem(10);
            margin-left: rem(20);
        }

        &.frm_required_field:after {
            display: none;
        }
    }

    .newsletter-zip-field {

        .field-background {
            padding: rem(5) rem(5) rem(5) rem(20);
            border-radius: rem(25);
            background-color: color(white);
        }

        input[type="text"]{
            border: 0;
            width: calc(100% - 20px);
            margin-top: rem(2);
            padding: rem(5);
            padding-left: rem(5) !important;
            border-radius: 0;

            &:active, &:focus{
                outline-color: color(gray);
                outline-width: rem(1);
            }
        }

        .frm_error {
            font:{
                family: $font-myriad-pro !important;
                style: normal !important;
                weight: 500 !important;
                size: rem(10);
            };
            color: color(white);
            margin-top: rem(10);
        }

        &.frm_required_field:after {
            display: none;
        }
    }

    .newsletter-submit {
        button {
            min-width: rem(125);
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    @include for-size('phone-only') {
        .newsletter-email-field, .newsletter-zip-field {
            width: rem(225);
            margin-bottom: rem(10);

            .frm_error {
                margin-left: 0;
            }
        }

        .newsletter-submit {
            width: rem(225);

            button {
                margin: 0 auto;
                display: block;
            }
        }
    }

    @include for-size('tablet-landscape-up'){
        margin-left: rem(20);

        .h-headline{
            font-size: rem(20);
        }
    }
}

.ftr--email-signup {
    .frm_forms {
        .frm_message {
            @include for-size('tablet-landscape-up') {
                min-width: rem(300);
                margin-left: rem(20);
            }

            @include for-size('desktop-up') {
                min-width: rem(400);
                margin-left: rem(20);
            }
        }
    }
}


// Formidable Overrides
.with_frm_style{
    --field-height: 47px !important;
}

.frm_forms{
    .steps{
        font-weight: bold;
    }

    .frm_required_field{
        position: relative;

        input, select{
            padding-left: rem(20) !important;
        }

        &:after{
            content: "*";
            color: red;
            position: absolute;
            top: rem(16);
            left: rem(6);

            @media (width: 600px) {
                top: rem(16) !important;
            }

            @include for-size('tablet-portrait-up'){
                top: 50%;
                left: rem(8);
            }
        }

        @include for-size('phone-only'){
            &.no-float-label{
                 &:after{
                    top: rem(37);
                 }
            }
        }

        &.horizontal_radio, &.inline-required-mark{
            &:after{
                position: relative;
                top: 0;
                left: 0;
                display: none;
            }

            .frm_primary_label{
                &:after{
                    content: "*";
                    color: red;
                }
            }
        }
    }

    .frm_arrow_icon{
        position: relative;

        &:before{
            display: block;
            content: "";
            width: rem(13);
            height: rem(13);
            position: absolute;
            top: rem(19);
            right: rem(10);
            background-image: url("/app/themes/rnrtires/assets/img/arrow.svg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            pointer-events: none;

            @media (width: 600px) {
                top: rem(20) !important;
            }

            @include for-size('tablet-portrait-up'){
                top: rem(35);
            }
        }

        &.no-float-label{
             &:before{
                top: rem(39);

                @media (width: 600px) {
                    top: rem(39) !important;
                }
                @include for-size('tablet-portrait-up'){
                     top: rem(41);
                }
             }
        }
    }

    .frm_date_icon{
        position: relative;

        &:before{
            display: block;
            content: "";
            position: absolute;
            bottom: rem(14);
            right: rem(10);
            width: rem(18);
            height: rem(18);
            background-image: url("/app/themes/rnrtires/assets/img/calendar.svg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            pointer-events: none;
        }

        &.frm_inside_container{}
    }

    .frm_section_spacing{
         @extend .h-title--lg;
         width: 100%;
         margin: 0 0 rem(20) 0;
    }
}

.frm_style_formidable-style{
    &.with_frm_style{

        .form-field{
            margin-bottom: rem(14) !important;
        }

        input[type="checkbox"], input[type="radio"]{
            -webkit-appearance: none !important;
            width: rem(15) !important;
            height: rem(15) !important;
            background-color: color(white) !important;
            border: rem(1) color(gray) solid !important;
            border-radius: rem(5) !important;

            &:checked{
                background-color: color(red--dark) !important;
                border-color: color(red--dark) !important;
                background:{
                    image: url("/app/themes/rnrtires/assets/img/checkmark.png");
                    size: 77.5%;
                    repeat: no-repeat;
                    position: center center;
                };
            }
        }

        input[type="text"], input[type="tel"], input[type="email"], input[type="number"], textarea, select{
            @include formFieldStyles();
        }

        .frm-show-form{
            .frm_section_heading{
                h3{
                    padding-top: 0;
                    margin-top: rem(8) !important;
                    margin-bottom: rem(20) !important;
                    border-top: 0 !important;
                }
            }
        }

        .frm_primary_label{
            font:{
                size: rem(12);
                weight: bold;
            };
            color: color(dark);
            margin:{
                bottom: rem(5);
            };

            .frm_required{
                display: none;
            }
        }

        .frm_gap{
            display: none;
        }

        @include for-specific-size(601){
            .frm_form_field{
                padding-top: rem(15);
            }

            .frm_gap{
                display: block;
            }

            .form-field{
                margin-bottom: rem(5) !important;
            }
        }

        .frm_top_container:not(.no-float-label){
            position: relative;
            margin-top: rem(15);
            .frm_primary_label{
                position: absolute;
                transform: translateY(-20px);
            }

            @include for-specific-size(601){
                margin-top: 0;
            }
        }

        .no-float-label{
            padding-top: 0;
        }

        .frm_inside_container {
            .frm_primary_label{
                max-height: 0;
                padding: 0;
                margin: 0;
                border-radius: rem(5);
                overflow: hidden;
            }
        }

        .frm_checkbox{
            label, input{
                padding-top: 0 !important;
                margin-top: 0 !important;
            }
        }

        .frm_form_field{
            &.frm_right_container{
                grid-template-columns: rem(25) auto !important;
            }
        }

        .frm_prev_page{
            background: color(gray--dark) !important;
            box-shadow: none !important;;
            border: rem(2) color(gray--dark) solid !important;
            padding: rem(12) rem(30) !important;
            border-radius: rem(50) !important;
            font:{
                family: $font-myriad-pro !important;
                style: normal !important;
                weight: 700 !important;
            };
            @extend .btn;

             &:hover, &:focus, &:active{
                color: color(white) !important;
                background-color: color(gray--medium) !important;
                border: rem(2) color(white) solid !important;
            }
        }

        .frm_button_submit{
            background: color(gray--dark) !important;
            box-shadow: none !important;;
            border: rem(2) color(gray--dark) solid !important;
            padding: rem(12) rem(30) !important;
            border-radius: rem(50) !important;
            font:{
                family: $font-myriad-pro !important;
                style: normal !important;
                weight: 700 !important;
            };
            @extend .btn;

            &.frm_final_submit{
                background: color(red--dark) !important;
                border: rem(2) color(red--dark) solid !important;
            }

             &:hover, &:focus, &:active{
                color: color(white) !important;
                background-color: color(gray--medium) !important;
                border: rem(2) color(white) solid !important;
            }
        }

        .frm_button_submit_thin{
            padding: rem(7.5) rem(24) !important;
        }

        .rnr-email-checkbox{
            grid-template-columns: initial;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
}

.with_frm_style .frm_inside_container > input::placeholder,
.with_frm_style .frm_inside_container > textarea::placeholder {
	opacity: 1 !important;
}

.frm_combo_inputs_container, .frm_grid_container, .frm_form_fields .frm_section_heading, .frm_form_fields .frm_fields_container {
    grid-template-columns: repeat(12, 6.5%);
}